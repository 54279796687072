import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from "@mui/material";
import axios from "axios";

const UpdateDocument = ({ open, onClose, studentId }) => {
  const [files, setFiles] = useState({
    studentPhoto: null,
    aadharCard: null,
    panCard: null,
    casteValidationPhoto: null,
    casteCertificate: null,
    leavingCertificate: null,
    domicile: null,
    birthCertificate: null,
    disabilityCertificate: null,
    studentSign: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles((prevFiles) => ({
      ...prevFiles,
      [name]: files[0],
    }));
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    Object.keys(files).forEach((key) => {
      if (files[key]) formData.append(key, files[key]);
    });

    try {
      await axios.put(`https://pjsofttech.in:9443/${studentId}/updateDocuments`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      onClose();
      alert("Documents updated successfully!");
    } catch (error) {
      console.error("Error updating documents:", error);
      alert("Failed to update documents. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Documents</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          {Object.keys(files).map((key) => (
            <Grid item xs={12} key={key}>
              <TextField
                type="file"
                name={key}
                label={key.replace(/([A-Z])/g, " $1").trim()} // Convert camelCase to Title Case
                onChange={handleFileChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Update Documents
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDocument;
