import React, { useEffect, useState } from "react";
import { Pie } from "@nivo/pie";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";

const TwoYearComparison = () => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [data, setData] = useState([]);
  const [year1, setYear1] = useState(2023);
  const [year2, setYear2] = useState(2024);

  const fetchData = async (y1, y2) => {
    try {
      const response = await axios.get(
        ` https://pjsofttech.in:19443/revenue/twoyears?year1=${y1}&year2=${y2}&institutecode=${getInstituteCode()}`
      );
      const revenueData = response.data;

      // Transform API response to format suitable for Pie chart
      const chartData = [
        { id: `${y1}`, label: `${y1}`, value: revenueData[y1] },
        { id: `${y2}`, label: `${y2}`, value: revenueData[y2] },
      ];

      setData(chartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(year1, year2);
  }, [year1, year2]);

  const handleYear1Change = (event) => {
    setYear1(event.target.value);
  };

  const handleYear2Change = (event) => {
    setYear2(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography style={{ fontWeight: "bold" }}>
          Monthly Salary Yearly Comparison
        </Typography>

        <div style={{ display: "flex", gap: "16px", marginLeft: "auto" }}>
          <FormControl style={{ marginBottom: "16px", minWidth: "120px" }}>
            <TextField
            select 
             label="Year1"
              value={year1}
              onChange={handleYear1Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>

          <FormControl style={{ marginBottom: "16px", minWidth: "120px" }}>
            <TextField
              select 
              label="Year2"
              value={year2}
              onChange={handleYear2Change}
            >
              {Array.from({ length: 15 }, (_, i) => 2024 - 7 + i).map(
                (year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                )
              )}
            </TextField>
          </FormControl>
        </div>
      </div>

      <Pie
        data={data}
        width={300}
        height={300}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={({ id }) => (id === `${year1}` ? "#3498DB" : "#FF6F61")} // Custom colors for each year
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabel={(d) => `${d.id}: ${d.value}`}
        sliceLabel={(d) => `${d.value}`}
      />
    </div>
  );
};

export default TwoYearComparison;
