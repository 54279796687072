import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Exam = () => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [examData, setExamData] = useState({ id: null, exam: "" });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    fetchexams();
  }, []);

  const fetchexams = async () => {
    const response = await axios.get(
      `https://pjsofttech.in:19443/AllExams?institutecode=${getInstituteCode()}`
    );
    setExams(response.data);
  };

  const handleOpenAddDialog = () => {
    setExamData({ id: null, exam: "" });
    setEditing(false);
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onAddExamSubmit = async (e) => {
    e.preventDefault();
    if (!examData.exam) {
      MySwal.fire({
        title: "Error!",
        text: "Please provide exam name.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    try {
      if (editing) {
        await axios.put(
          `https://pjsofttech.in:19443/updateExam/${examData.id}`,
          { exam: examData.exam }
        );
        MySwal.fire({
          title: "Updated!",
          text: "The exam has been updated successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      } else {
        await axios.post(
          `https://pjsofttech.in:19443/createExam?institutecode=${getInstituteCode()}`,
          { exam: examData.exam }
        );
        MySwal.fire({
          title: "Added!",
          text: "The exam has been added successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      }
      fetchexams();
      handleCloseAddDialog();
    } catch (error) {
      console.error("There was an error adding/updating the exam!", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error processing your request.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleEditExam = (exam) => {
    setExamData({ id: exam.id, exam: exam.exam });
    setEditing(true);
    handleOpenAddDialog();
  };

  const handleDeleteExam = (exam) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete exam: ${exam.exam}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteExam(exam.id);
      }
    });
  };

  const deleteExam = async (examId) => {
    try {
      await axios.delete(`https://pjsofttech.in:19443/deleteExam/${examId}`);
      MySwal.fire({
        title: "Deleted!",
        text: "The exam has been deleted successfully.",
        icon: "success",
        timer: 1500,
        showConfirmButton: false,
      });
      fetchexams();
    } catch (error) {
      console.error("Error deleting exam:", error);
      MySwal.fire({
        title: "Error!",
        text: "There was an error deleting the exam.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const filteredExams = exams.filter(exam =>
    exam.exam.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div >
      <Grid container spacing={2} sx={{ marginTop: 3 }}>
      <Typography  variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
        Total Exams: {filteredExams.length}
      </Typography>
      <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Exam"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            placeholder="Search Exam"
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
          >
            Add Exam
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <TableContainer>
          <Table  className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>Exam ID</TableCell>
                <TableCell>Exam Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredExams.map((exam) => (
                <TableRow key={exam.id}>
                  <TableCell>{exam.id}</TableCell>
                  <TableCell>{exam.exam}</TableCell>
                  <TableCell>
                    {/* <Button
                      color="secondary"
                      onClick={() => handleEditExam(exam)}
                      size="small"
                      variant="contained"
                      style={{ marginRight: 5 }}
                    >
                      Edit
                    </Button> */}
                    <Button
                      color="error"
                      onClick={() => handleDeleteExam(exam)}
                      size="small"
                      variant="contained"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog for Adding/Editing Exam */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>{editing ? "Edit Exam" : "Add Exam"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editing ? "Edit the exam." : "Add a new exam."}
          </DialogContentText>
          <form onSubmit={onAddExamSubmit}>
            <TextField
              autoFocus
              margin="dense"
              label="Exam Name"
              fullWidth
              variant="outlined"
              value={examData.exam}
              onChange={(e) => setExamData({ ...examData, exam: e.target.value })}
            />
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                {editing ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Exam;
