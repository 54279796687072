// src/components/Report.js
import React from "react";

const ShipmentReport = () => {
  return (
    <div style={{ padding: "20px" }}>Report Content</div>
  );
};

export default ShipmentReport;
