import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { CssBaseline } from "@mui/material";
import "./App.css"; // Import the global styles
// Import the UserContext
import { UserProvider, UserContext } from "./layouts/UserContext";

// Import components
import ForgotPassword from "./layouts/ForgotPassword";
import Login from "./layouts/Login";
import Layout from "./layouts/layout";

import AdminProfile from "./layouts/AdminProfile"; // Ensure this path is correct
import Memo from "./layouts/Memo";
import ManageMemo from "./layouts/ManageMemo";
import ManageNotification from "./layouts/ManageNotification";
import CombineDashboard from "./layouts/CombineDashboard";
import FloatingHelpDeskButton from "./layouts/FloatingHelpDeskButton";
import HelpDesk from "./layouts/HelpDesk";
//import bill from "./layouts/bill";
import AddSubAdmin from "./layouts/AddSubAdmin";
import Settings from "./layouts/Settings";

import CreateAccount from "./layouts/CreateAccount";
import ManageLeave from "./Employee_System/Leave/ManageLeave";
import AddMemo from "./Employee_System/Memo/AddMemo";
import ManageEmpMemo from "./Employee_System/Memo/ManageEmpMemo";
import AddNotice from "./Employee_System/Memo/AddNotice";
import Login1 from "./layouts/Login1";
import Bill from "./layouts/bill";
import EmpDash from "./layouts/EmpDash";
import StudentDashboard from "./Student/StudentDashboard";
import StudentForm from "./Student/StudentForm";
import StudentLists from "./Student/StudentLists";
import StudentReport from "./Student/StudentReport";
import StudentRequest from "./Student/StudentRequest";
import AddField from "./Student/AddField";
import AttenDash from "./layouts/AttenDash";
import FeesDashboard from "./Student/Fees/FeesDashboard";
import FeeInvoice from "./Student/Fees/FeeInvoice";
import InvoiceList from "./Student/Fees/InvoiceList";
import AddFee from "./Student/Fees/AddFee";
import FeeTable from "./Student/Fees/FeeTable";
import AddMedium from "./Student/Fees/AddMedium";
import FeeReport from "./Student/Fees/FeeReport";
import IncomeExpenseManager from "./Income Expense/IncomeExpenseManager";
import AdmissionManager from "./Addmission/AdmissionManager";
import EnquiryManager from "./Enquiry/EnquiryManager";
import ShipmentManager from "./Shipment/ShipmentManager";
import PayrollManager from "./Payroll/PayrollManager";

import EmployeeManager from "./Employee_System/Employee/EmployeeManager";
import AttendanceManager from "./Employee_System/Attendance/AttendanceManager";
import EmployeeSalaryManager from "./Employee_System/Salary/EmployeeSalaryManager";
import MemoManager from "./Employee_System/Memo/MemoManager";
import AdmissionForm from "./Addmission/AdmissionForm";
import AddmissionSource from "./Addmission/AddmissionSource";
import AddGuide from "./Addmission/AddGuide";
import StudentList from "./Addmission/StudentList";
import AdmissionAddCourse from './Addmission/AdmissionAddCourse'
import IncomeExpenseDashboard from "./Income Expense/IncomeExpenseDashboard";
import AddIncomeExpense from "./Income Expense/AddIncomeExpense";
import AddCategory from "./Income Expense/AddCategory";
import { Category } from "@mui/icons-material";
import AddUser from "./Income Expense/AddUser";
import DashBoard from "./Enquiry/DashBoard";
import Report from "./Enquiry/Report";
import Exam from "./Enquiry/ExamPages/Exam";
import Source from "./Enquiry/ConductBy/Sources/Source";
import Conduct from "./Enquiry/ConductBy/Conduct";
import AddInquiry from './Enquiry/pages/AddInquiry'

import BookName from "./Shipment/Bookname";
import VendorName from "./Shipment/VendorName";
import Type from "./Shipment/Type";
import Status from "./Shipment/Status";
import ShipmentDashboard from "./Shipment/ShipmentDashboard";
import ShipmentForm from "./Shipment/Shipmentform";

import EmployeeManagerLayout from "./Employee_System/Employee/EmployeeManagerLayout";
import EmployeeDashboard from "./Employee_System/Employee/EmployeeDashboard";
import EmployeeList from "./Employee_System/Employee/EmployeeList";
import EmpReport from "./Employee_System/Employee/EmpReport";
import ManageHoliday from "./Employee_System/Employee/ManageHoliday";
import AddEmployee from "./Employee_System/Employee/AddEmployee";
import AddEmpcategory from "./Employee_System/Employee/AddEmpcategory";
import AddDepartment from "./Employee_System/Employee/AddDepartment";
import AttendanceManagerLayout from "./Employee_System/Attendance/AttendanceManagerLayout";
import TodaysAttendance from "./Employee_System/Attendance/TodaysAttendance";
import ManageAttendance from "./Employee_System/Attendance/ManageAttendance";
import EmpDetails from "./Employee_System/Salary/EmpDetails";
import SalaryTable from "./Employee_System/Salary/SalaryTable";
import EmployeeSalaryManagerLayout from "./Employee_System/Salary/EmployeeSalaryManagerLayout";
import SalaryDashBoard from "./Employee_System/Salary/SalaryDashboard";
import AddSalary from "./Employee_System/Salary/AddSalary";
import ViewAttendance from "./Employee_System/Attendance/ViewAttendance";
import ShipmentReport from "./Shipment/ShipmentReport";
import TodaysNotification from "./layouts/TodaysNotification";
import { ThemeProvider } from '@mui/material/styles';
import theme from './layouts/theme'; // Import your custom theme
import StudentManager from "./Student/StudentManager";
import FeesManager from "./Student/Fees/FeesManager";
import Invoice from "./Student/Fees/Invoice";




import PayrollDashboard from "./Payroll/PayrollDashboard";
import MonthlySalary from "./Payroll/MonthlySalary";
import HourlySalary from "./Payroll/HourlySalary";
import ManageTeacher from "./Payroll/ManageTeacher";
import HourlyRate from "./Payroll/HourlyRate";
import AddSubject from "./Payroll/AddSubject";
import TeacherExam from './Payroll/TeacherExam';
import AddTeacher from "./Payroll/AddTeacher";


function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );
  const location = useLocation();
  const showHelpDeskButton =
    location.pathname !== "/" &&
    location.pathname !== "/create-account" &&
    location.pathname !== "/systems";

  const { user } = useContext(UserContext);

  useEffect(() => {
    const checkAuth = () => {
      const authStatus = localStorage.getItem("isAuthenticated") === "true";
      setIsAuthenticated(authStatus);
    };
    checkAuth();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/loginPage" element={<Login1 />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}> */}

        <Route path="/layout/*" element={<Layout />}>
          <Route path="admin-profile" element={<AdminProfile />} />
          <Route path="todaysNotifications" element={<TodaysNotification/>}/>

          <Route path="combineDash" element={<CombineDashboard />} />

          <Route path="Memo-manager" element={<MemoManager />} />
        
          {/* Enquiry Routes */}

            {/* Route for Enquiry Manager */}
      <Route path="enquiry-manager" element={<EnquiryManager />}>
        {/* Nested routes for Enquiry Manager */}
        <Route index element={<DashBoard />} />
        <Route path="add-inquiry" element={<AddInquiry />} />
        <Route path="report" element={<Report />} />
        <Route path="exam" element={<Exam />} />
        <Route path="source" element={<Source />} />
        <Route path="conduct" element={<Conduct />} />
      </Route>

        {/* AdmissionManager and nested routes */}
        <Route path="Admission-manager" element={<AdmissionManager />}>
            <Route index element={<AdmissionForm />} />
            
            <Route path="admission-form" element={<AdmissionForm />} />
            <Route path="add-course" element={<AdmissionAddCourse />} />
            <Route path="add-source" element={<AddmissionSource />} />
            <Route path="add-guide" element={<AddGuide />} />
            <Route path="student-list" element={<StudentList />} />
          </Route>

          {/* Income Expense */}

          <Route path="income-expense-manager" element={<IncomeExpenseManager />}>
          {/* Nested routes for IncomeExpenseManager */}
          <Route index element={<IncomeExpenseDashboard />} />
          <Route path="add-income-expense" element={<AddIncomeExpense />} />
          <Route path="add-category" element={<AddCategory />} />
          <Route path="category" element={<Category />} />
          <Route path="add-user" element={<AddUser />} />
        </Route>

          {/* Employee */}

        {/* Employee Manager Route with Nested Routes */}
        <Route path="employee-manager" element={<EmployeeManager />}>
          <Route index element={<EmployeeDashboard />} />   {/* Default page */}
          <Route path="add-employee" element={<AddEmployee />} />
          <Route path="employee-list" element={<EmployeeList />} />
          <Route path="emp-report" element={<EmpReport />} />
          <Route path="add-category" element={<AddEmpcategory />} />
          <Route path="add-department" element={<AddDepartment />} />
          <Route path="manage-holiday" element={<ManageHoliday />} />
        </Route>

          <Route path="EmpDash" element={<EmpDash />} />
          <Route path="AttenDash" element={<AttenDash />} />

          <Route path="attendance-manager/*" element={<AttendanceManagerLayout />}>
          <Route index element={<TodaysAttendance />} />  {/* Default route */}
          <Route path="manage-attendance" element={<ManageAttendance />} />
        <Route path="View-attendance/:empID" element={<ViewAttendance />} />

        </Route>

        {/*shipmenmt routes*/}
      <Route path="shipment-manager" element={<ShipmentManager/>}>
      <Route index element={<ShipmentDashboard/>}/>
      <Route path="shipment-form" element={<ShipmentForm/>}/>
      <Route path="shipment-report" element={<ShipmentReport/>}/>
      <Route path="shipment-type" element={<Type/>}/>
      <Route path="status" element={<Status/>}/>
      <Route path="bookname" element={<BookName/>}/>
      <Route path="vendorname" element={<VendorName/>}/>
      </Route>




      

     {/* Employee Salary Manager */}
     <Route path="employee-salary-manager/*" element={<EmployeeSalaryManagerLayout />}>
            <Route index element={<SalaryDashBoard />} />
            <Route path="salary-list" element={<SalaryTable />} />
            <Route path="add-salary/:empID" element={<AddSalary />} />
            <Route path="add-detail" element={<EmpDetails />} />
            

          </Route>

          <Route path="ManageLeave" element={<ManageLeave />} />

          <Route path="manageLeave" element={<ManageLeave />} />
          <Route path="helpDesk" element={<HelpDesk />} />
          <Route path="bill" element={<Bill />} />
          <Route path="subadmin" element={<AddSubAdmin />} />
          <Route path="Settings" element={<Settings />} />

          <Route path="addmemo" element={<AddMemo />} />
          <Route path="manageEmpmemo" element={<ManageEmpMemo />} />
          <Route path="addnotice" element={<AddNotice />} />

          {/* Student */}
          <Route path="student-manager" element={<StudentManager />}>
          <Route path="StudentDashboard" element={<StudentDashboard />} />
          <Route path="StudentForm" element={<StudentForm />} />
          <Route path="StudentLists" element={<StudentLists />} />
          <Route path="StudentReport" element={<StudentReport />} />
          <Route path="StudentRequest" element={<StudentRequest />} />
          <Route path="AddField" element={<AddField />} />
          </Route>


          {/* Teacher Payroll  */}
          <Route path="payroll-manager" element={<PayrollManager/>}>
          <Route index element={<PayrollDashboard/>}/>
          <Route path="monthly-salary" element={<MonthlySalary/>}/>
          <Route path="hourly-salary" element={<HourlySalary/>}/>
          <Route path="teachers-list" element={<ManageTeacher/>}/>
          <Route path="hourly-rate" element={<HourlyRate/>}/>
          <Route path="add-subject" element={<AddSubject/>}/>
          <Route path="add-exam" element={<TeacherExam/>}/>
          <Route path="add-teacher" element={<AddTeacher/>}/>
          </Route>





          {/*Fees */}
          <Route path="fees-manager" element={<FeesManager />}>
          <Route path="FeesDashboard" element={<FeesDashboard />} />
          {/* <Route path="FeeInvoice" element={<FeeInvoice />} /> */}
          <Route path="Invoice" element={<Invoice />} />
          <Route path="InvoiceList" element={<InvoiceList />} />
          <Route path="AddFee" element={<AddFee />} />
          <Route path="FeeTable" element={<FeeTable />} />
          <Route path="AddMedium" element={<AddMedium />} />
          <Route path="FeeReport" element={<FeeReport />} />
          </Route>
          {/* <Route path="account" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Account /></ProtectedRoute>} /> */}
        </Route>

        {/* Enquiry Routes */}

            {/* Route for Enquiry Manager */}
      {/* <Route path="enquiry-manager" element={<EnquiryManager />}>
        {/* Nested routes for Enquiry Manager */}
        {/* <Route index element={<DashBoard />} />
        <Route path="add-inquiry" element={<AddInquiry />} />
        <Route path="report" element={<Report />} />
        <Route path="exam" element={<Exam />} />
        <Route path="source" element={<Source />} />
        <Route path="conduct" element={<Conduct />} />
      </Route> */}

      

      </Routes>
      {showHelpDeskButton && <FloatingHelpDeskButton />}
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <UserProvider>
        <CssBaseline />
        <AppContent />
      </UserProvider>
    </Router>
  </ThemeProvider>
  );
}

export default App;


// import React, { useState, useEffect, useContext, lazy, Suspense } from "react";
// import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { CssBaseline, CircularProgress } from "@mui/material";
// import "./App.css"; // Import the global styles
// import { UserProvider, UserContext } from "./layouts/UserContext";
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './layouts/theme'; // Import your custom theme

// // Lazy load components
// const ForgotPassword = lazy(() => import("./layouts/ForgotPassword"));
// const Login = lazy(() => import("./layouts/Login"));
// const Layout = lazy(() => import("./layouts/layout"));
// const AdminProfile = lazy(() => import("./layouts/AdminProfile"));
// const Memo = lazy(() => import("./layouts/Memo"));
// const ManageMemo = lazy(() => import("./layouts/ManageMemo"));
// const ManageNotification = lazy(() => import("./layouts/ManageNotification"));
// const CombineDashboard = lazy(() => import("./layouts/CombineDashboard"));
// const FloatingHelpDeskButton = lazy(() => import("./layouts/FloatingHelpDeskButton"));
// const HelpDesk = lazy(() => import("./layouts/HelpDesk"));
// const AddSubAdmin = lazy(() => import("./layouts/AddSubAdmin"));
// const Settings = lazy(() => import("./layouts/Settings"));
// const CreateAccount = lazy(() => import("./layouts/CreateAccount"));
// const ManageLeave = lazy(() => import("./Employee_System/Leave/ManageLeave"));
// const AddMemo = lazy(() => import("./Employee_System/Memo/AddMemo"));
// const ManageEmpMemo = lazy(() => import("./Employee_System/Memo/ManageEmpMemo"));
// const AddNotice = lazy(() => import("./Employee_System/Memo/AddNotice"));
// const Login1 = lazy(() => import("./layouts/Login1"));
// const Bill = lazy(() => import("./layouts/bill"));
// const EmpDash = lazy(() => import("./layouts/EmpDash"));
// const StudentDashboard = lazy(() => import("./Student/StudentDashboard"));
// const StudentForm = lazy(() => import("./Student/StudentForm"));
// const StudentLists = lazy(() => import("./Student/StudentLists"));
// const StudentReport = lazy(() => import("./Student/StudentReport"));
// const StudentRequest = lazy(() => import("./Student/StudentRequest"));
// const AddField = lazy(() => import("./Student/AddField"));
// const AttenDash = lazy(() => import("./layouts/AttenDash"));
// const FeesDashboard = lazy(() => import("./Student/Fees/FeesDashboard"));
// const FeeInvoice = lazy(() => import("./Student/Fees/FeeInvoice"));
// const InvoiceList = lazy(() => import("./Student/Fees/InvoiceList"));
// const AddFee = lazy(() => import("./Student/Fees/AddFee"));
// const FeeTable = lazy(() => import("./Student/Fees/FeeTable"));
// const AddMedium = lazy(() => import("./Student/Fees/AddMedium"));
// const FeeReport = lazy(() => import("./Student/Fees/FeeReport"));
// const IncomeExpenseManager = lazy(() => import("./Income Expense/IncomeExpenseManager"));
// const AdmissionManager = lazy(() => import("./Addmission/AdmissionManager"));
// const EnquiryManager = lazy(() => import("./Enquiry/EnquiryManager"));
// const ShipmentManager = lazy(() => import("./Shipment/ShipmentManager"));
// const EmployeeManager = lazy(() => import("./Employee_System/Employee/EmployeeManager"));
// const AttendanceManager = lazy(() => import("./Employee_System/Attendance/AttendanceManager"));
// const EmployeeSalaryManager = lazy(() => import("./Employee_System/Salary/EmployeeSalaryManager"));
// const MemoManager = lazy(() => import("./Employee_System/Memo/MemoManager"));
// const AdmissionForm = lazy(() => import("./Addmission/AdmissionForm"));
// const AddmissionSource = lazy(() => import("./Addmission/AddmissionSource"));
// const AddGuide = lazy(() => import("./Addmission/AddGuide"));
// const StudentList = lazy(() => import("./Addmission/StudentList"));
// const AdmissionAddCourse = lazy(() => import('./Addmission/AdmissionAddCourse'));
// const IncomeExpenseDashboard = lazy(() => import("./Income Expense/IncomeExpenseDashboard"));
// const AddIncomeExpense = lazy(() => import("./Income Expense/AddIncomeExpense"));
// const AddCategory = lazy(() => import("./Income Expense/AddCategory"));
// const Category =lazy(() => import("./Income Expense/Category"));
// const AddUser = lazy(() => import("./Income Expense/AddUser"));
// const DashBoard = lazy(() => import("./Enquiry/DashBoard"));
// const Report = lazy(() => import("./Enquiry/Report"));
// const Exam = lazy(() => import("./Enquiry/ExamPages/Exam"));
// const Source = lazy(() => import("./Enquiry/ConductBy/Sources/Source"));
// const Conduct = lazy(() => import("./Enquiry/ConductBy/Conduct"));
// const AddInquiry = lazy(() => import('./Enquiry/pages/AddInquiry'));
// const BookName = lazy(() => import("./Shipment/Bookname"));
// const VendorName = lazy(() => import("./Shipment/VendorName"));
// const Type = lazy(() => import("./Shipment/Type"));
// const Status = lazy(() => import("./Shipment/Status"));
// const ShipmentDashboard = lazy(() => import("./Shipment/ShipmentDashboard"));
// const ShipmentForm = lazy(() => import("./Shipment/Shipmentform"));
// const EmployeeManagerLayout = lazy(() => import("./Employee_System/Employee/EmployeeManagerLayout"));
// const EmployeeDashboard = lazy(() => import("./Employee_System/Employee/EmployeeDashboard"));
// const EmployeeList = lazy(() => import("./Employee_System/Employee/EmployeeList"));
// const EmpReport = lazy(() => import("./Employee_System/Employee/EmpReport"));
// const ManageHoliday = lazy(() => import("./Employee_System/Employee/ManageHoliday"));
// const AddEmployee = lazy(() => import("./Employee_System/Employee/AddEmployee"));
// const AddEmpcategory = lazy(() => import("./Employee_System/Employee/AddEmpcategory"));
// const AddDepartment = lazy(() => import("./Employee_System/Employee/AddDepartment"));
// const AttendanceManagerLayout = lazy(() => import("./Employee_System/Attendance/AttendanceManagerLayout"));
// const TodaysAttendance = lazy(() => import("./Employee_System/Attendance/TodaysAttendance"));
// const ManageAttendance = lazy(() => import("./Employee_System/Attendance/ManageAttendance"));
// const EmpDetails = lazy(() => import("./Employee_System/Salary/EmpDetails"));
// const SalaryTable = lazy(() => import("./Employee_System/Salary/SalaryTable"));
// const EmployeeSalaryManagerLayout = lazy(() => import("./Employee_System/Salary/EmployeeSalaryManagerLayout"));
// const SalaryDashBoard = lazy(() => import("./Employee_System/Salary/SalaryDashboard"));
// const AddSalary = lazy(() => import("./Employee_System/Salary/AddSalary"));
// const ViewAttendance = lazy(() => import("./Employee_System/Attendance/ViewAttendance"));
// const ShipmentReport = lazy(() => import("./Shipment/ShipmentReport"));
// const TodaysNotification = lazy(() => import("./layouts/TodaysNotification"));

// function AppContent() {
//   const [isAuthenticated, setIsAuthenticated] = useState(
//     localStorage.getItem("isAuthenticated") === "true"
//   );
//   const location = useLocation();
//   const showHelpDeskButton =
//     location.pathname !== "/" &&
//     location.pathname !== "/create-account" &&
//     location.pathname !== "/systems";

//   const { user } = useContext(UserContext);

//   useEffect(() => {
//     const checkAuth = () => {
//       const authStatus = localStorage.getItem("isAuthenticated") === "true";
//       setIsAuthenticated(authStatus);
//     };
//     checkAuth();
//   }, []);

//   const handleLogin = () => {
//     setIsAuthenticated(true);
//     localStorage.setItem("isAuthenticated", "true");
//   };

//   return (
//     <>
//      <Suspense fallback={<CircularProgress />}>
//       <Routes>
//         <Route path="/" element={<Login onLogin={handleLogin} />} />
//         <Route path="/create-account" element={<CreateAccount />} />
//         <Route path="/loginPage" element={<Login1 />} />
//         <Route path="/forgot-password" element={<ForgotPassword />} />
//         {/* <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}> */}

//         <Route path="/layout/*" element={<Layout />}>
//           <Route path="admin-profile" element={<AdminProfile />} />
//           <Route path="todaysNotifications" element={<TodaysNotification/>}/>

//           <Route path="combineDash" element={<CombineDashboard />} />

//           <Route path="Memo-manager" element={<MemoManager />} />
        
//           {/* Enquiry Routes */}

//             {/* Route for Enquiry Manager */}
//       <Route path="enquiry-manager" element={<EnquiryManager />}>
//         {/* Nested routes for Enquiry Manager */}
//         <Route index element={<DashBoard />} />
//         <Route path="add-inquiry" element={<AddInquiry />} />
//         <Route path="report" element={<Report />} />
//         <Route path="exam" element={<Exam />} />
//         <Route path="source" element={<Source />} />
//         <Route path="conduct" element={<Conduct />} />
//       </Route>

//         {/* AdmissionManager and nested routes */}
//         <Route path="Admission-manager" element={<AdmissionManager />}>
//             <Route index element={<AdmissionForm />} />
            
//             <Route path="admission-form" element={<AdmissionForm />} />
//             <Route path="add-course" element={<AdmissionAddCourse />} />
//             <Route path="add-source" element={<AddmissionSource />} />
//             <Route path="add-guide" element={<AddGuide />} />
//             <Route path="student-list" element={<StudentList />} />
//           </Route>

//           {/* Income Expense */}

//           <Route path="income-expense-manager" element={<IncomeExpenseManager />}>
//           {/* Nested routes for IncomeExpenseManager */}
//           <Route index element={<IncomeExpenseDashboard />} />
//           <Route path="add-income-expense" element={<AddIncomeExpense />} />
//           <Route path="add-category" element={<AddCategory />} />
//           <Route path="category" element={<Category />} />
//           <Route path="add-user" element={<AddUser />} />
//         </Route>

//           {/* Employee */}

//         {/* Employee Manager Route with Nested Routes */}
//         <Route path="employee-manager" element={<EmployeeManager />}>
//           <Route index element={<EmployeeDashboard />} />   {/* Default page */}
//           <Route path="add-employee" element={<AddEmployee />} />
//           <Route path="employee-list" element={<EmployeeList />} />
//           <Route path="emp-report" element={<EmpReport />} />
//           <Route path="add-category" element={<AddEmpcategory />} />
//           <Route path="add-department" element={<AddDepartment />} />
//           <Route path="manage-holiday" element={<ManageHoliday />} />
//         </Route>

//           <Route path="EmpDash" element={<EmpDash />} />
//           <Route path="AttenDash" element={<AttenDash />} />

//           <Route path="attendance-manager/*" element={<AttendanceManagerLayout />}>
//           <Route index element={<TodaysAttendance />} />  {/* Default route */}
//           <Route path="manage-attendance" element={<ManageAttendance />} />
//         <Route path="View-attendance/:empID" element={<ViewAttendance />} />

//         </Route>

//         {/*shipmenmt routes*/}
//       <Route path="shipment-manager" element={<ShipmentManager/>}>
//       <Route index element={<ShipmentDashboard/>}/>
//       <Route path="shipment-form" element={<ShipmentForm/>}/>
//       <Route path="shipment-report" element={<ShipmentReport/>}/>
//       <Route path="shipment-type" element={<Type/>}/>
//       <Route path="status" element={<Status/>}/>
//       <Route path="bookname" element={<BookName/>}/>
//       <Route path="vendorname" element={<VendorName/>}/>
//       </Route>

//      {/* Employee Salary Manager */}
//      <Route path="employee-salary-manager/*" element={<EmployeeSalaryManagerLayout />}>
//             <Route index element={<SalaryDashBoard />} />
//             <Route path="salary-list" element={<SalaryTable />} />
//             <Route path="add-salary/:empID" element={<AddSalary />} />
//             <Route path="add-detail" element={<EmpDetails />} />
            

//           </Route>

//           <Route path="ManageLeave" element={<ManageLeave />} />

//           <Route path="manageLeave" element={<ManageLeave />} />
//           <Route path="helpDesk" element={<HelpDesk />} />
//           <Route path="bill" element={<Bill />} />
//           <Route path="subadmin" element={<AddSubAdmin />} />
//           <Route path="Settings" element={<Settings />} />

//           <Route path="addmemo" element={<AddMemo />} />
//           <Route path="manageEmpmemo" element={<ManageEmpMemo />} />
//           <Route path="addnotice" element={<AddNotice />} />

//           {/* Student */}
//           <Route path="StudentDashboard" element={<StudentDashboard />} />
//           <Route path="StudentForm" element={<StudentForm />} />
//           <Route path="StudentLists" element={<StudentLists />} />
//           <Route path="StudentReport" element={<StudentReport />} />
//           <Route path="StudentRequest" element={<StudentRequest />} />
//           <Route path="AddField" element={<AddField />} />

//           {/*Fees */}

//           <Route path="FeesDashboard" element={<FeesDashboard />} />
//           <Route path="FeeInvoice" element={<FeeInvoice />} />
//           <Route path="InvoiceList" element={<InvoiceList />} />
//           <Route path="AddFee" element={<AddFee />} />
//           <Route path="FeeTable" element={<FeeTable />} />
//           <Route path="AddMedium" element={<AddMedium />} />
//           <Route path="FeeReport" element={<FeeReport />} />

//           {/* <Route path="account" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Account /></ProtectedRoute>} /> */}
//         </Route>

//         {/* Enquiry Routes */}

//             {/* Route for Enquiry Manager */}
//       {/* <Route path="enquiry-manager" element={<EnquiryManager />}>
//         {/* Nested routes for Enquiry Manager */}
//         {/* <Route index element={<DashBoard />} />
//         <Route path="add-inquiry" element={<AddInquiry />} />
//         <Route path="report" element={<Report />} />
//         <Route path="exam" element={<Exam />} />
//         <Route path="source" element={<Source />} />
//         <Route path="conduct" element={<Conduct />} />
//       </Route> */}

      

//       </Routes>
//       {showHelpDeskButton && <FloatingHelpDeskButton />}
//       </Suspense>
//     </>
//   );
// }

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//     <Router>
//       <UserProvider>
//         <CssBaseline />
//         <AppContent />
//       </UserProvider>
//     </Router>
//   </ThemeProvider>
//   );
// }

// export default App;
