

import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableContainer,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

const MySwal = withReactContent(Swal);

const HourlyRate = () => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [hourlyRates, setHourlyRates] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedHourlyRate, setSelectedHourlyRate] = useState(null);
  const [hourlyRateData, setHourlyRateData] = useState({ hourlyRate: "" });

  useEffect(() => {
    fetchHourlyRates();
  }, []);

  const fetchHourlyRates = async () => {
    try {
      const response = await axios.get(
        `https://pjsofttech.in:19443/getAllHourlyRates?instituteCode=${getInstituteCode()}`
      );
      setHourlyRates(response.data);
    } catch (error) {
      console.error("Error fetching hourly rates:", error);
    }
  };

  const handleOpenAddDialog = () => {
    setHourlyRateData({ hourlyRate: "" });
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (hourlyRate) => {
    setSelectedHourlyRate(hourlyRate);
    setOpenEditDialog(true);
    setHourlyRateData(hourlyRate);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedHourlyRate(null);
  };

  const handleDeleteHourlyRate = (hourlyRate) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to delete this hourly rate: ${hourlyRate.hourlyRate}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHourlyRate(hourlyRate.id);
      }
    });
  };

  const deleteHourlyRate = async (id) => {
    try {
      await axios.delete(`https://pjsofttech.in:19443/deleteHourlyRate/${id}`);
      MySwal.fire(
        "Deleted!",
        "The hourly rate has been deleted successfully.",
        "success"
      );
      fetchHourlyRates(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting hourly rate:", error);
      MySwal.fire(
        "Error!",
        "There was an error deleting the hourly rate.",
        "error"
      );
    }
  };

  const handleAddHourlyRate = async (e) => {
    e.preventDefault();
    if (!hourlyRateData.hourlyRate) {
      MySwal.fire("Error!", "Please fill out the necessary fields.", "error");
      return;
    }

    try {
      await axios.post(
        `https://pjsofttech.in:19443/createHourlyRate?institutecode=${getInstituteCode()}`,
        hourlyRateData
      );
      MySwal.fire(
        "Success!",
        "The hourly rate has been added successfully.",
        "success"
      );
      handleCloseAddDialog();
      fetchHourlyRates();
    } catch (error) {
      console.error("Error adding hourly rate:", error);
      MySwal.fire(
        "Error!",
        "There was an error adding the hourly rate.",
        "error"
      );
    }
  };

  const handleUpdateHourlyRate = async (e) => {
    e.preventDefault();
    if (!hourlyRateData.hourlyRate) {
      MySwal.fire("Error!", "Please fill out the necessary fields.", "error");
      return;
    }

    try {
      await axios.put(
        `https://pjsofttech.in:19443/updateHourlyRate/${selectedHourlyRate.id}`,
        hourlyRateData
      );
      MySwal.fire(
        "Success!",
        "The hourly rate has been updated successfully.",
        "success"
      );
      fetchHourlyRates(); // Refresh the list after update
      handleCloseEditDialog();
    } catch (error) {
      console.error("Error updating hourly rate:", error);
      MySwal.fire(
        "Error!",
        "There was an error updating the hourly rate.",
        "error"
      );
    }
  };

  // Filtered hourly rates based on search term
  const filteredHourlyRates = hourlyRates.filter((rate) =>
    rate.hourlyRate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Grid container spacing={2} sx={{ marginTop: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ marginTop: 3, whiteSpace: "nowrap" }}
        >
          Total Rates: {filteredHourlyRates.length}
        </Typography>
        <Grid item xs={12} sm={1.6} className="textField-root">
          <TextField
            label="Search Rate"
            variant="outlined"
            fullWidth
            placeholder="Search Hourly Rate"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenAddDialog}
            sx={{ marginTop: 1 }}
          >
            Add Hourly Rate
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
      <TableContainer>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Hourly Rate</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredHourlyRates.map((hourlyRate) => (
              <TableRow key={hourlyRate.id}>
                <TableCell>{hourlyRate.id}</TableCell>
                <TableCell>{hourlyRate.hourlyRate}</TableCell>
                <TableCell>
                  {/* <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleOpenEditDialog(hourlyRate)}
                    size="small"
                  >
                    Edit
                  </Button> */}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteHourlyRate(hourlyRate)}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>

      {/* Add Hourly Rate Dialog */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add Hourly Rate</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Hourly Rate"
            fullWidth
            variant="outlined"
            value={hourlyRateData.hourlyRate}
            onChange={(e) => setHourlyRateData({ hourlyRate: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddHourlyRate} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Hourly Rate Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Hourly Rate</DialogTitle>
        <DialogContent>
          {selectedHourlyRate && (
            <TextField
              autoFocus
              margin="dense"
              label="Hourly Rate"
              fullWidth
              variant="outlined"
              value={hourlyRateData.hourlyRate}
              onChange={(e) =>
                setHourlyRateData({
                  ...hourlyRateData,
                  hourlyRate: e.target.value,
                })
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateHourlyRate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HourlyRate;
