// import React, { useState } from "react";
// import { Button, Grid, Paper, Typography } from "@mui/material";
// import axios from "axios";
// import "react-toastify/dist/ReactToastify.css";
// // import "./AstricRed.css";

// const UploadDocuments = ({ emailAddress }) => {
//   const [formData, setFormData] = useState({
//     studentphoto: null,
//     studentSign: null,
//     aadharcard: null,
//     pancard: null,
//     castevalidationphoto: null,
//     castecertificate: null,
//     leavingcertificate: null,
//     domicile: null,
//     birthcertificate: null,
//     disabilitycertificate: null,
//   });

//   const [error, setError] = useState("");
//   const [uploadStatus, setUploadStatus] = useState("");
//   const [previews, setPreviews] = useState({
//     studentphoto: null,
//     studentSign: null,
//   });

//   const handleInputChange = (e) => {
//     const { name, files } = e.target;
//     const file = files[0];

//     if (file && file.type !== "image/jpeg") {
//       setError("Only JPEG files are allowed.");
//       return;
//     }

//     setFormData({ ...formData, [name]: file });
//     setError("");

//     if (name === "studentphoto" || name === "studentSign") {
//       const previewUrl = URL.createObjectURL(file);
//       setPreviews((prevPreviews) => ({ ...prevPreviews, [name]: previewUrl }));
//     }

//     console.log(`Selected file for ${name}:`, file);
//   };

//   const handleUpload = async (type) => {
//     const uploadEndpoints = {
//       studentphoto: `https://pjsofttech.in:9443/saveStudentPhoto/${emailAddress}`,
//       studentSign: `https://pjsofttech.in:9443/studentSign/${emailAddress}`,
//       aadharcard: `https://pjsofttech.in:9443/aadharcard/${emailAddress}`,
//       pancard: `https://pjsofttech.in:9443/savepancardPhoto/${emailAddress}`,
//       castevalidationphoto: `https://pjsofttech.in:9443/saveCastevalidationPhoto/${emailAddress}`,
//       castecertificate: `https://pjsofttech.in:9443/savecastecertificatePhoto/${emailAddress}`,
//       leavingcertificate: `https://pjsofttech.in:9443/saveleavingcertificatePhoto/${emailAddress}`,
//       domicile: `https://pjsofttech.in:9443/savedomicilecertificatePhoto/${emailAddress}`,
//       birthcertificate: `https://pjsofttech.in:9443/savebirthcertificatPhoto/${emailAddress}`,
//       disabilitycertificate: `https://pjsofttech.in:9443/disabilitycertificate/${emailAddress}`,
//     };

//     const file = formData[type];
//     if (!file) {
//       setError(`No file selected for ${type}.`);
//       return;
//     }

//     const uploadFormData = new FormData();
//     uploadFormData.append(type, file);

//     try {
//       console.log(`Uploading ${type} to ${uploadEndpoints[type]}`);
//       const response = await axios.post(uploadEndpoints[type], uploadFormData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       console.log(`${type} uploaded successfully`, response.data);
//       setUploadStatus(`${type} uploaded successfully`);
//       //toast.success(`${type} uploaded successfully`); // Display toast notification
//     } catch (error) {
//       console.error(`Error uploading ${type}`, error);
//       if (error.response) {
//         console.error("Error response data:", error.response.data);
//         console.error("Error response status:", error.response.status);
//         console.error("Error response headers:", error.response.headers);
//       }
//       setError(`Error uploading ${type}. Please try again.`);
//     }
//   };

//   return (
//     <div>
//     <Paper
//       elevation={3}
//       style={{ padding: "20px", margin: "20px auto" }}
//     >
//       <Typography variant="h5" align="center" gutterBottom>
//         Upload Documents
//       </Typography>
//       {error && (
//         <Typography color="error" align="center" gutterBottom>
//           {error}
//         </Typography>
//       )}
//       {uploadStatus && (
//         <Typography color="primary" align="center" gutterBottom>
//           {uploadStatus}
//         </Typography>
//       )}
//       <form>
//         <Grid container spacing={2}>
//           {Object.keys(formData).map((key) => (
//             <Grid item xs={12} key={key}>
//               <input
//                 type="file"
//                 name={key}
//                 accept="image/jpeg"
//                 onChange={handleInputChange}
//                 required
//                 InputLabelProps={{ className: "required-asterisk" }}
//               />
//               {key === "studentphoto" && previews.studentphoto && (
//                 <img
//                   src={previews.studentphoto}
//                   alt="Student"
//                   style={{ height: "100px", width: "150px", marginTop: "10px" }}
//                 />
//               )}
//               {key === "studentSign" && previews.studentSign && (
//                 <img
//                   src={previews.studentSign}
//                   alt="Student Sign Preview"
//                   style={{ height: "50px", width: "200px", marginTop: "10px" }}
//                 />
//               )}
//               <Button
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 onClick={() => handleUpload(key)}
//                 disabled={!formData[key]}
//               >
//                 Upload {key.charAt(0).toUpperCase() + key.slice(1)}
//               </Button>
//             </Grid>
//           ))}
//         </Grid>
//       </form>
//     </Paper>
//     </div>
//   );
// };

// export default UploadDocuments;


import React, { useState } from "react";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";

const UploadDocuments = ({ emailAddress }) => {
  const [formData, setFormData] = useState({
    studentphoto: null,
    studentSign: null,
    aadharcard: null,
    pancard: null,
    castevalidationphoto: null,
    castecertificate: null,
    leavingcertificate: null,
    domicile: null,
    birthcertificate: null,
    disabilitycertificate: null,
  });

  const [error, setError] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [previews, setPreviews] = useState({
    studentphoto: null,
    studentSign: null,
  });

  const handleInputChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file && file.type !== "image/jpeg") {
      setError("Only JPEG files are allowed.");
      Swal.fire("Error", "Only JPEG files are allowed.", "error");
      return;
    }

    setFormData({ ...formData, [name]: file });
    setError("");

    if (name === "studentphoto" || name === "studentSign") {
      const previewUrl = URL.createObjectURL(file);
      setPreviews((prevPreviews) => ({ ...prevPreviews, [name]: previewUrl }));
    }

    console.log(`Selected file for ${name}:`, file);
  };

  const handleUpload = async (type) => {
    const uploadEndpoints = {
      studentphoto: `https://pjsofttech.in:9443/saveStudentPhoto/${emailAddress}`,
      studentSign: `https://pjsofttech.in:9443/studentSign/${emailAddress}`,
      aadharcard: `https://pjsofttech.in:9443/aadharcard/${emailAddress}`,
      pancard: `https://pjsofttech.in:9443/savepancardPhoto/${emailAddress}`,
      castevalidationphoto: `https://pjsofttech.in:9443/saveCastevalidationPhoto/${emailAddress}`,
      castecertificate: `https://pjsofttech.in:9443/savecastecertificatePhoto/${emailAddress}`,
      leavingcertificate: `https://pjsofttech.in:9443/saveleavingcertificatePhoto/${emailAddress}`,
      domicile: `https://pjsofttech.in:9443/savedomicilecertificatePhoto/${emailAddress}`,
      birthcertificate: `https://pjsofttech.in:9443/savebirthcertificatPhoto/${emailAddress}`,
      disabilitycertificate: `https://pjsofttech.in:9443/disabilitycertificate/${emailAddress}`,
    };

    const file = formData[type];
    if (!file) {
      setError(`No file selected for ${type}.`);
      Swal.fire("Error", `No file selected for ${type}.`, "error");
      return;
    }

    const uploadFormData = new FormData();
    uploadFormData.append(type, file);

    try {
      console.log(`Uploading ${type} to ${uploadEndpoints[type]}`);
      const response = await axios.post(uploadEndpoints[type], uploadFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(`${type} uploaded successfully`, response.data);
      Swal.fire("Success", `${type} uploaded successfully.`, "success");
      // setUploadStatus(`${type} uploaded successfully`);
    } catch (error) {
      console.error(`Error uploading ${type}`, error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      }
      setError(`Error uploading ${type}. Please try again.`);
      Swal.fire(
        "Upload Failed",
        `Error uploading ${type}. Please try again.`,
        "error"
      );
    }
  };

  return (
    <div>
      <Paper elevation={3} style={{ padding: "20px", margin: "20px auto"}}>
        <Typography variant="h5" align="center" gutterBottom>
          Upload Documents
        </Typography>
        {error && (
          <Typography color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
        {uploadStatus && (
          <Typography color="primary" align="center" gutterBottom>
            {uploadStatus}
          </Typography>
        )}
        <form>
          <Grid container spacing={2}>
            {Object.keys(formData).map((key, index) => (
              <Grid item xs={12} sm={4} key={key}>
                <TextField
                  type="file"
                  name={key}
                  accept="image/jpeg"
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                />
                {key === "studentphoto" && previews.studentphoto && (
                  <img
                    src={previews.studentphoto}
                    alt="Student"
                    style={{ height: "100px", width: "150px", marginTop: "10px" }}
                  />
                )}
                {key === "studentSign" && previews.studentSign && (
                  <img
                    src={previews.studentSign}
                    alt="Student Sign Preview"
                    style={{ height: "50px", width: "200px", marginTop: "10px" }}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleUpload(key)}
                  disabled={!formData[key]}
                  style={{ marginTop: "10px" }}
                >
                  Upload {key.charAt(0).toUpperCase() + key.slice(1)}
                </Button>
              </Grid>
            ))}
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default UploadDocuments;
