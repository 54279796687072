// src/components/Dashboard.js
import React, { useState, useEffect } from "react";



const ShipmentDashboard = () => {
  
  return (
    <div>

      <h1>Dashboard</h1>
    </div>
  );
};

export default ShipmentDashboard;

// import React from 'react'

// export default function ShipmentDashboard() {
//   return (
//     <div>
      
//     </div>
//   )
// }

