// EmployeeManager.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import StudentDashboard from "./StudentDashboard";
import StudentForm from "./StudentForm";
import StudentLists from "./StudentLists";
import StudentReport from "./StudentReport";
import StudentRequest from "./StudentRequest";
import AddField from "./AddField";
import StudentManagerLayout from './StudentManagerLayout';

const StudentManager = () => {
  return (
    <Routes>
      {/* Main route that renders the layout with the sidebar */}
      <Route path="/" element={<StudentManagerLayout />}>
        {/* Nested routes inside EmployeeManagerLayout */}
        <Route index element={<StudentDashboard />} />
        <Route path="StudentForm" element={<StudentForm />} />
        <Route path="StudentLists" element={<StudentLists />} />
        <Route path="StudentReport" element={<StudentReport />} />
        <Route path="StudentRequest" element={<StudentRequest />} />
        <Route path="AddField" element={<AddField />} />
      </Route>
    </Routes>
  );
};

export default StudentManager;
