import React, { useState, useEffect} from "react";
import {
  Button,
  TextField,
  MenuItem,
  Grid,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const Invoice = () => {
  const [formData, setFormData] = useState({
    invoice:"",
    studentName: "",
    rollNo: "",
    standard: "",
    medium: "",
    division: "",
    tuitionFee: 0,
    admissionFee: 0,
    practicalFee: 0,
    computerClassFee: 0,
    examFees: 0,
    uniformFee: 0,
    transportBusFee: 0,
    hostelFee: 0,
    buildingFundFee: 0,
    libraryFees: 0,
    sportFees: 0,
    totalFeesAmount: 0,
    feesAmount: "",
    discount: 0,
    discountedAmount: 0,
    gst: 0,
    GSTAmount: 0,
    netfeeamount: 0,
    lateFeeCharges: 0,
    pendingFeesAmount: 0,
    feesCollectionType: "",
    feesStatus: "",
    feesType: "",
    transactionId: "",
    gstNo: "",
    showGstNo: false,
    registrationDate: "",
  });

  const [standards, setStandards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [standardData, setStandardData] = useState({});

  const institutecode = () => localStorage.getItem("institutecode");

  // Fetch mediums
  useEffect(() => {
    const fetchMediums = async () => {
      try {
        const response = await axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode()}`);
        setMediums(response.data);
      } catch (error) {
        console.error("Error fetching mediums:", error);
      }
    };
    fetchMediums();
  }, []);

  // Fetch divisions
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await axios.get(`https://pjsofttech.in:9443/getallDivision?institutecode=${institutecode()}`);
        setDivisions(response.data);
      } catch (error) {
        console.error("Error fetching divisions:", error);
      }
    };
    fetchDivisions();
  }, []);

  // Fetch standards when medium is selected
  useEffect(() => {
    if (formData.medium) {
      const fetchStandards = async () => {
        try {
          const response = await axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode()}`);
          setStandards(response.data);
        } catch (error) {
          console.error("Error fetching standards:", error);
        }
      };
      fetchStandards();
    }
  }, [formData.medium]);

  // Fetch standard data when standard is selected
  useEffect(() => {
    if (formData.standard) {
      const fetchStandardData = async () => {
        try {
          const response = await axios.get(`https://pjsofttech.in:9443/standardfees/bystandardandmedium`, {
            params: {
              medium: formData.medium,
              standard: formData.standard,
              institutecode: institutecode(),
            },
          });
          setStandardData(response.data[0] || {}); // Assuming response is an array with one object
        } catch (error) {
          console.error("Error fetching standard data:", error);
        }
      };
      fetchStandardData();
    }
  }, [formData.standard]);

  // Update formData when standardData changes
  useEffect(() => {
    if (Object.keys(standardData).length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        ...standardData,
        totalFeesAmount: calculateTotalFees(standardData),
      }));
    }
  }, [standardData]);

  // Calculate total fees
  const calculateTotalFees = (data) => {
    const {
      tuitionFee = 0,
      admissionFee = 0,
      practicalFee = 0,
      computerClassFee = 0,
      examFees = 0,
      uniformFee = 0,
      transportBusFee = 0,
      hostelFee = 0,
      buildingFundFee = 0,
      libraryFees = 0,
      sportFees = 0,
      lateFeeCharges = 0,
    } = data;
    return (
      tuitionFee +
      admissionFee +
      practicalFee +
      computerClassFee +
      examFees +
      uniformFee +
      transportBusFee +
      hostelFee +
      buildingFundFee +
      libraryFees +
      sportFees +
      lateFeeCharges
    );
  };

    // Calculate pending fees amount
    const calculatePendingFeesAmount = () => {
        const pendingAmount = formData.totalFeesAmount - formData.feesAmount;
        setFormData((prevData) => ({
          ...prevData,
          pendingFeesAmount: pendingAmount,
        }));
      };
    
      // Update pending fees amount whenever feesAmount changes
      useEffect(() => {
        calculatePendingFeesAmount();
      }, [formData.feesAmount, formData.totalFeesAmount]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

    // Handle discount and GST calculations
    const handleDiscountChange = (e) => {
        const discount = parseFloat(e.target.value);
        const discountedAmount = (formData.totalFeesAmount * discount) / 100;
        const gstAmount = ((formData.totalFeesAmount - discountedAmount) * formData.gst) / 100;
        const netFeeAmount = formData.totalFeesAmount - discountedAmount + gstAmount;
        const pendingFeesAmount = netFeeAmount + formData.lateFeeCharges;
    
        setFormData((prevData) => ({
          ...prevData,
          discount,
          discountedAmount,
          GSTAmount: gstAmount,
          netfeeamount: netFeeAmount,
          pendingFeesAmount,
        }));
      };
    
      const handleGstChange = (e) => {
        const gst = parseFloat(e.target.value);
        const gstAmount = ((formData.totalFeesAmount - formData.discountedAmount) * gst) / 100;
        const netFeeAmount = formData.totalFeesAmount - formData.discountedAmount + gstAmount;
        const pendingFeesAmount = netFeeAmount + formData.lateFeeCharges;
    
        setFormData((prevData) => ({
          ...prevData,
          gst,
          GSTAmount: gstAmount,
          netfeeamount: netFeeAmount,
          pendingFeesAmount,
        }));
      };
    

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://pjsofttech.in:9443/SaveFees?institutecode=${institutecode()}`, formData);
      Swal.fire("Success", "Form submitted successfully", "success");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [randomNumber, setRandomNumber] = useState(null);
  // Function to generate unique random number
  const generateRandomNumber = () => {
    const min = 1000;
    const max = 9999;
    const generatedNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return generatedNumber;
  };
  useEffect(() => {
    const randomNum = generateRandomNumber();
    setRandomNumber(randomNum); // Set the generated random number
  }, []);

  return (
    <div>
    
        <form onSubmit={handleSubmit}>
        <Grid item xs={12} className="textField-root">

              <Paper elevation={3} sx={{ p: 3 }}>
              <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Invoice Number"
                name="invoice"
                value={randomNumber}
                onChange={handleChange}
                variant="outlined"
                InputProps={{
                    readOnly: true,
                  }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                label="Medium"
                name="medium"
                value={formData.medium}
                onChange={handleChange}
                variant="outlined"
                required
              >
                {mediums.map((medium) => (
                  <MenuItem key={medium.id} value={medium.mediumname}>
                    {medium.mediumname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formData.medium && (
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  fullWidth
                  label="Standard"
                  name="standard"
                  value={formData.standard}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {standards.map((standard) => (
                    <MenuItem key={standard.standardId} value={standard.standard}>
                      {standard.standard}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <TextField
                select
                fullWidth
                label="Division"
                name="division"
                value={formData.division}
                onChange={handleChange}
                variant="outlined"
                required
              >
                {divisions.map((division) => (
                  <MenuItem key={division.id} value={division.division}>
                    {division.division}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Student Name"
                name="studentName"
                value={formData.studentName}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Roll No"
                name="rollNo"
                value={formData.rollNo}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
           
            </Grid>
            </Paper>

            <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box p={2} mt={2} mb={2}>
                    <Grid container spacing={2}>

            {/* Fee fields */}
            {["tuitionFee", "admissionFee", "practicalFee", "computerClassFee", "examFees", "uniformFee", "transportBusFee", "hostelFee", "buildingFundFee", "libraryFees", "sportFees", "totalFeesAmount"].map((field) => (
              <Grid item xs={12} sm={4} key={field}>
                <TextField
                  fullWidth
                  label={field.replace(/([A-Z])/g, " $1")}
                  name={field}
                  type="number"
                  value={formData[field]}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    readOnly: field === "totalFeesAmount",
                  }}
                />
              </Grid>
            ))}
                 <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Late Fee Charges"
                  type="number"
                  name="lateFeeCharges"
                  value={formData.lateFeeCharges}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
                 <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Discount (%)"
                  name="discount"
                  type="number"
                  value={formData.discount}
                  onChange={handleDiscountChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="DiscountedAmount"
                  type="number"
                  name="discountedAmount"
                  value={formData.discountedAmount}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="GST (%)"
                  name="gst"
                  type="number"
                  value={formData.gst}
                  onChange={handleGstChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="GSTAmount"
                  type="number"
                  name="GSTAmount"
                  value={formData.GSTAmount}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Netfeeamount"
                  type="number"
                  name="netfeeamount"
                  value={formData.netfeeamount}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="PaidAmount"
                  type="number"
                  name="feesAmount"
                  value={formData.feesAmount}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Pending Fees Amount"
                  type="number"
                  name="pendingFeesAmount"
                  value={formData.pendingFeesAmount}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  select
                  label="Fees Status"
                  name="feesStatus"
                  value={formData.feesStatus}
                  onChange={handleChange}
                  variant="outlined"
                  required
                >
                  {["Complete", "Pending"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                  <TextField
                  select
                  fullWidth
                    label="Fees Collection Type"
                    name="feesCollectionType"
                    value={formData.feesCollectionType}
                    onChange={handleChange}
                  >
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="cheque">Cheque</MenuItem>
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Transaction ID"
                  name="transactionId"
                  value={formData.transactionId}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Payment Date"
                  type="date"
                  name="registrationDate"
                  value={formData.registrationDate}
                  onChange={handleChange}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showGstNo"
                      checked={formData.showGstNo}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Show GST No."
                />
              </Grid>
              {/* Add conditional rendering for GST no */}
              {formData.showGstNo && (
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label="GST No."
                    name="gstNo"
                    value={formData.gstNo}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              )}
              

            </Grid>

</Box>
</Paper>
</Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
    </div>
  );
};

export default Invoice;
