import React, { useEffect, useState } from "react";
import axios from "axios";
import { ResponsiveBar } from "@nivo/bar";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Typography,
  Grid,
} from "@mui/material";

const monthsList = [
  { month: "January", count: 0 },
  { month: "February", count: 0 },
  { month: "March", count: 0 },
  { month: "April", count: 0 },
  { month: "May", count: 0 },
  { month: "June", count: 0 },
  { month: "July", count: 0 },
  { month: "August", count: 0 },
  { month: "September", count: 0 },
  { month: "October", count: 0 },
  { month: "November", count: 0 },
  { month: "December", count: 0 },
];

const customTheme = {
  border: {
    light: "#d3d3d3",
    dark: "#333",
  },
  grid: {
    stroke: "#eee",
    strokeWidth: 1,
  },
};

export default function AllMonthsSalaryRevenueChart() {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [data, setData] = useState(monthsList);
  const [year, setYear] = useState(2024); // Default year

  // Generate the year options for the dropdown
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 15 }, (_, i) => currentYear - 7 + i);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ` https://pjsofttech.in:19443/revenueByPayingDate?institutecode=${getInstituteCode()}&year=${year}`
        );
        const apiData = response.data;

        // const updatedData = monthsList.map((monthObj) => {
        //   const monthData = apiData.find((item) => item[0] === monthObj.month);
        //   return {
        //     month: monthObj.month,
        //     count: monthData ? monthData[1] : 0,
        //   };
        // });

        const updatedData = monthsList.map((monthObj) => {
          const monthData = apiData.find((item) => item[0] === monthObj.month);
          return {
            month: monthObj.month.slice(0, 3), // Take the first three letters of the month
            count: monthData ? monthData[1] : 0,
          };
        });

        setData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [year]); // Fetch data whenever the year changes

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent={"center"} gap={1}>
        <Grid item>
          <Typography variant="body1">
            All Months Salary Revenue By Year
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            select
            label="Year"
            value={year}
            onChange={handleYearChange}
          >
            {years.map((yr) => (
              <MenuItem key={yr} value={yr}>
                {yr}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <div style={{ width: "80%", marginTop: "16px" }}>
        <Box height={400}>
          <ResponsiveBar
            data={data}
            keys={["count"]}
            indexBy="month"
            margin={{ top: 20, right: 30, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={() => "#3498DB"} // Set bar color to #3498DB
            borderColor={customTheme.border.light}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Month",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            enableLabel={false}
            theme={customTheme}
          />
        </Box>
      </div>
    </Grid>
  );
}
