import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,Box
} from "@mui/material";
import AllMonthsMonthlySalaryCount from "./AllMonthsMonthlySalaryCount";
import AllMonthsSalaryRevenue from "./AllMonthsSalaryRevenue";
import AllMonthsSalaryCountChart from "./AllMonthsSalaryCountChart";
import TwoYearComparison from "./TwoYearComparison";
import AllMonthsSalaryRevenueChart from "./AllMonthsSalaryRevenueChart";

const PayrollDashboard = () => {
  // const getInstituteCode = () => localStorage.getItem("institutecode");
  return (
    <div sx={{ padding: 2, width: "100%" }}>
      <Box mt={1} textAlign="center" sx={{ width: "100%" }}>
       <Grid container xs={12}>
       <Grid item xs={12}>
       <AllMonthsMonthlySalaryCount />
       </Grid>
       <Grid item xs={12} mt={2}>
       <AllMonthsSalaryRevenue />
       </Grid>
       </Grid>

        {/* charts section  */}

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          mt={3}
          className="textField-root"
        >
          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
              <AllMonthsSalaryCountChart />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
              <AllMonthsSalaryRevenueChart />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
              <TwoYearComparison />
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} style={{ padding: "16px" }}>
            {/* <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
              <TwoYearComparison />
            </Paper> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default PayrollDashboard;
