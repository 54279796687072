// EmployeeManager.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import FeesDashboard from "./FeesDashboard";
import FeeInvoice from "./FeeInvoice";
import InvoiceList from "./InvoiceList";
import AddFee from "./AddFee";
import FeeTable from "./FeeTable";
import AddMedium from "./AddMedium";
import FeeReport from "./FeeReport";
import FeesManagerLayout from './FeesManagerLayout';
import Invoice from "./Invoice";

const StudentManager = () => {
  return (
    <Routes>
      {/* Main route that renders the layout with the sidebar */}
      <Route path="/" element={<FeesManagerLayout />}>
        {/* Nested routes inside EmployeeManagerLayout */}
        <Route index element={<FeesDashboard />} />
        {/* <Route path="FeeInvoice" element={<FeeInvoice />} /> */}
        <Route path="Invoice" element={<Invoice />} />
        <Route path="InvoiceList" element={<InvoiceList />} />
        <Route path="AddFee" element={<AddFee />} />
        <Route path="FeeTable" element={<FeeTable />} />
        {/* <Route path="AddMedium" element={<AddMedium />} /> */}
        <Route path="FeeReport" element={<FeeReport />} />
      </Route>
    </Routes>
  );
};

export default StudentManager;
