// // import React, { useEffect, useState } from "react";
// // import {
// //   Card,
// //   CardContent,
// //   Typography,
// //   Grid,
// //   CircularProgress,
// // } from "@mui/material";
// // import axios from "axios";

// // const apiEndpoints = [
// //   { label: "Today", timeframe: "today", backgroundColor: "#3498DB" }, // Light Peach
// //   { label: "Total", timeframe: "total", backgroundColor: "#FF6F61" }, // Light Cyan
// //   { label: "7 Days", timeframe: "7days", backgroundColor: "#F9E79F" }, // Light Pink
// //   { label: "30 Days", timeframe: "30days", backgroundColor: "#3498DB" }, // Light Orange
// //   { label: "365 Days", timeframe: "365days", backgroundColor: "#FF6F61" }, // Light Green
// // ];

// // export default function AllMonthsMonthlySalaryCount() {
// //   const [data, setData] = useState({});
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const responses = await Promise.all(
// //           apiEndpoints.map((endpoint) =>
// //             axios.get(
// //               ` https://pjsofttech.in:19443/MonthlySalaryReportcount?timeframe=${endpoint.timeframe}&institutecode=IST610`
// //             )
// //           )
// //         );

// //         const results = responses.reduce((acc, response, index) => {
// //           acc[apiEndpoints[index].timeframe] = response.data; // Assuming API response has a count property
// //           return acc;
// //         }, {});

// //         setData(results);
// //       } catch (err) {
// //         setError(err.message);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   if (loading) {
// //     return <CircularProgress />;
// //   }

// //   if (error) {
// //     return <Typography color="error">Error: {error}</Typography>;
// //   }

// //   return (
// //     <div>
// //       <Grid container spacing={1}>
// //         {apiEndpoints.map((endpoint) => (
// //           <Grid item xs={2} key={endpoint.timeframe}>
// //             <Card  sx={{ backgroundColor: endpoint.backgroundColor }}>
// //               <CardContent>
// //                 <div style={{display:'flex', justifyContent:'space-between'}}>
// //                 <Typography variant="h7" component="div">
// //                   {endpoint.label}
// //                 </Typography>
// //                 <Typography variant="h5" color="text.secondary">
// //                   {data[endpoint.timeframe] || 0}
// //                 </Typography>
// //                 </div>
// //               </CardContent>
// //             </Card>
// //           </Grid>
// //         ))}
// //       </Grid>
// //     </div>
// //   );
// // }
// import React, { useEffect, useState } from "react";
// import {
//   Card,
//   CardContent,
//   Typography,
//   Grid,
//   CircularProgress,
// } from "@mui/material";
// import axios from "axios";

// const apiEndpoints = [
//   { label: "Today", timeframe: "today", backgroundColor: "#3498DB" }, 
//   { label: "Total", timeframe: "total", backgroundColor: "#FF6F61" }, 
//   { label: "7 Days", timeframe: "7days", backgroundColor: "#F9E79F" }, 
//   { label: "30 Days", timeframe: "30days", backgroundColor: "#3498DB" }, 
//   { label: "365 Days", timeframe: "365days", backgroundColor: "#FF6F61" }, 
// ];

// export default function AllMonthsMonthlySalaryCount() {
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const responses = await Promise.all(
//           apiEndpoints.map((endpoint) =>
//             axios.get(
//               ` https://pjsofttech.in:19443/MonthlySalaryReportcount?timeframe=${endpoint.timeframe}&institutecode=IST610`
//             )
//           )
//         );

//         const results = responses.reduce((acc, response, index) => {
//           acc[apiEndpoints[index].timeframe] = response.data; // Assuming API response has a count property
//           return acc;
//         }, {});

//         setData(results);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <CircularProgress />;
//   }

//   if (error) {
//     return <Typography color="error">Error: {error}</Typography>;
//   }

//   return (
//     <div>
//       <Grid container spacing={1}>
//         {apiEndpoints.map((endpoint) => (
//           <Grid item xs={12} md={2} key={endpoint.timeframe}>
//             <Card sx={{ backgroundColor: endpoint.backgroundColor, height: "100%" }}>
//               <CardContent>
//                 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                   <Typography variant="h7" component="div">
//                     {endpoint.label}
//                   </Typography>
//                   <Typography variant="h5" color="text.secondary">
//                     {data[endpoint.timeframe] || 0}
//                   </Typography>
//                 </div>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const apiEndpoints = [
  { label: "Today", timeframe: "today", backgroundColor: "#3498DB" },
  { label: "Total", timeframe: "total", backgroundColor: "#FF6F61" },
  { label: "7 Days", timeframe: "7days", backgroundColor: "#F9E79F" },
  { label: "30 Days", timeframe: "30days", backgroundColor: "#3498DB" },
  { label: "365 Days", timeframe: "365days", backgroundColor: "#FF6F61" },
];

export default function AllMonthsMonthlySalaryCount() {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all(
          apiEndpoints.map((endpoint) =>
            axios.get(
              ` https://pjsofttech.in:19443/MonthlySalaryReportcount?timeframe=${endpoint.timeframe}&institutecode=${getInstituteCode()}`
            )
          )
        );

        const results = responses.reduce((acc, response, index) => {
          acc[apiEndpoints[index].timeframe] = response.data; // Assuming API response has a count property
          return acc;
        }, {});

        setData(results);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <div>
      <Grid container sx={{justifyContent:'space-between'}}>
        {apiEndpoints.map((endpoint) => (
          <Grid item xs={12} md={2} spacing={2} key={endpoint.timeframe}>
            <Card sx={{ backgroundColor: endpoint.backgroundColor, width: '110%' , borderRadius:'15px'}}>
              <CardContent >
                <div >
                  <Typography variant="h7" component="div">
                    {endpoint.label}
                  </Typography>
                  <Typography variant="h5">
                    {data[endpoint.timeframe] || 0}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
