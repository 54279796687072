// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Grid,
//   TextField,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   IconButton,
//   Typography,
// } from "@mui/material";
// import ConfirmationDialog from "./ConfirmationDialog";
// import EditIcon from "@mui/icons-material/Edit";
// import { ToastContainer, toast } from "react-toastify";
// import { Delete } from "@mui/icons-material";
// import Swal from "sweetalert2";

// export default function AddExam() {
//   const [standard, setStandard] = useState({ standard: "" });
//   const [medium, setMedium] = useState({ mediumname: "" });
//   const [standards, setStandards] = useState([]);
//   const [mediums, setMediums] = useState([]);
//   const [editStandard, setEditStandard] = useState(null);
//   const [editMedium, setEditMedium] = useState(null);
//   const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
//   const [deleteItemId, setDeleteItemId] = useState(null);
//   const [isDeletingStandard, setIsDeletingStandard] = useState(false);
//   const institutecode = () => localStorage.getItem("institutecode");

//   const onInputChangeStandard = (e) => {
//     setStandard({ ...standard, [e.target.name]: e.target.value });
//   };

//   const onInputChangeMedium = (e) => {
//     setMedium({ ...medium, [e.target.name]: e.target.value });
//   };

//   const onSubmitStandard = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(`https://pjsofttech.in:9443/createstandard?institutecode=${institutecode()}`, standard);
//       Swal.fire("Success", "Standard Added Successfully!", "success");
//       fetchStandards();
//     } catch (error) {
//       console.error("There was an error adding the standard!", error);
//     }
//   };

//   const onSubmitMedium = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post(`https://pjsofttech.in:9443/save?institutecode=${institutecode()}`, medium);
//       Swal.fire("Success", "Medium Added Successfully!", "success");
//       fetchMediums();
//     } catch (error) {
//       console.error("There was an error adding the medium!", error);
//     }
//   };

//   const fetchStandards = async () => {
//     try {
//       const result = await axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode()}`);
//       setStandards(result.data);
//     } catch (error) {
//       console.error("There was an error fetching the standards!", error);
//     }
//   };

//   const fetchMediums = async () => {
//     try {
//       const result = await axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode()}`);
//       setMediums(result.data);
//     } catch (error) {
//       console.error("There was an error fetching the mediums!", error);
//     }
//   };

//   const handleDeleteConfirmation = (id, isStandard) => {
//     setDeleteItemId(id);
//     setIsDeletingStandard(isStandard);
    
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         handleDeleteConfirm(id, isStandard);
//       }
//     });
//   };
  
//   const handleDeleteConfirm = async (id, isStandard) => {
//     try {
//       if (isStandard) {
//         await axios.delete(`https://pjsofttech.in:9443/deleteStandard/${id}`);
//         fetchStandards();
//       } else {
//         await axios.delete(`https://pjsofttech.in:9443/deleteMediumById/${id}`);
//         fetchMediums();
//       }
//       Swal.fire("Deleted!", "Item has been deleted.", "success");
//     } catch (error) {
//       console.error("Error deleting item:", error);
//     } finally {
//       setDeleteItemId(null);
//     }
//   };

//   const handleEditStandard = (standard) => {
//     setEditStandard(standard);
//     setStandard(standard);
//   };

//   const handleEditMedium = (medium) => {
//     setEditMedium(medium);
//     setMedium(medium);
//   };

//   const onSubmitEditStandard = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`https://pjsofttech.in:9443/updateStandard/${editStandard.id}`, standard);
//       Swal.fire("Success", "Standard Updated Successfully!", "success");
//       fetchStandards();
//       setEditStandard(null);
//       setStandard({ standard: "" });
//     } catch (error) {
//       console.error("There was an error updating the standard!", error);
//     }
//   };

//   const onSubmitEditMedium = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`https://pjsofttech.in:9443/updateMedium/${editMedium.mediumid}`, medium);
//       Swal.fire("Success", "Medium Updated Successfully!", "success");
//       fetchMediums();
//       setEditMedium(null);
//       setMedium({ mediumname: "" });
//     } catch (error) {
//       console.error("There was an error updating the medium!", error);
//     }
//   };

//   useEffect(() => {
//     fetchStandards();
//     fetchMediums();
//   }, []);

//   return (
//     <>
//       <ToastContainer />
//       <div style={{ marginTop: "10px" }}>
//         <Paper elevation={3} style={{ padding: "20px" }}>
//           <Grid container spacing={2} className='textField-root'>
//             <Grid item xs={6}>
//               <form onSubmit={editStandard ? onSubmitEditStandard : onSubmitStandard} className="examForm">
//                 <TextField
//                   type="text"
//                   label={editStandard ? "Edit Standard Name:" : "Add Standard/Class Name:"}
//                   className="form-control"
//                   placeholder="Enter The Standard/Class Name"
//                   name="standard"
//                   value={standard.standard}
//                   onChange={onInputChangeStandard}
//                   fullWidth
//                 />
//                 <Button type="submit" variant="contained" color="primary" id="submit" style={{ marginTop: "10px" }}>
//                   {editStandard ? "Update" : "Submit"}
//                 </Button>
//               </form>
//             </Grid>
//             <Grid item xs={6}>
//               <form onSubmit={editMedium ? onSubmitEditMedium : onSubmitMedium} className="examForm">
//                 <TextField
//                   type="text"
//                   label= {editMedium ? "Edit Medium Name:" : "Add Medium Name:"}
//                   className="form-control"
//                   placeholder="Enter The Medium Name"
//                   name="mediumname"
//                   value={medium.mediumname}
//                   onChange={onInputChangeMedium}
//                   fullWidth
//                 />
//                 <Button type="submit" variant="contained" color="primary" id="submit" style={{ marginTop: "10px" }}>
//                   {editMedium ? "Update" : "Submit"}
//                 </Button>
//               </form>
//             </Grid>
//           </Grid>
//         </Paper>
//       </div>
//       <div style={{ marginTop: "30px" }}>
//         <Paper elevation={3} style={{ padding: "10px" }}>
//           <Grid container spacing={1}>
//             <Grid item xs={6}>
//               <TableContainer component={Paper}>
//                 <Table className="table-root" size="small">
//                   <TableHead sx={{ background: "#f2f2f2" }}>
//                     <TableRow>
//                       <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>Standard</TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {standards.map((std) => (
//                       <TableRow key={std.id}>
//                         <TableCell>{std.id}</TableCell>
//                         <TableCell>{std.standard}</TableCell>
//                         <TableCell>
//                           <IconButton color="error" onClick={() => handleDeleteConfirmation(std.id, true)}>
//                             <Delete />
//                           </IconButton>
//                           <IconButton color="primary" onClick={() => handleEditStandard(std)}>
//                             <EditIcon />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Grid>
//             <Grid item xs={6}>
//               <TableContainer component={Paper}>
//                 <Table className="table-root" size="small">
//                   <TableHead sx={{ background: "#f2f2f2" }}>
//                     <TableRow>
//                       <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>Medium</TableCell>
//                       <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {mediums.map((med) => (
//                       <TableRow key={med.mediumid}>
//                         <TableCell>{med.mediumid}</TableCell>
//                         <TableCell>{med.mediumname}</TableCell>
//                         <TableCell>
//                           <IconButton color="error" onClick={() => handleDeleteConfirmation(med.mediumid, false)}>
//                             <Delete />
//                           </IconButton>
//                           <IconButton color="primary" onClick={() => handleEditMedium(med)}>
//                             <EditIcon />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </Grid>
//           </Grid>
//         </Paper>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from "@mui/material";
import ConfirmationDialog from "./ConfirmationDialog";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";

export default function AddExam() {
  const [standard, setStandard] = useState({ standard: "" });
  const [medium, setMedium] = useState({ mediumname: "" });
  const [division, setDivision] = useState({ division: "" });
  const [standards, setStandards] = useState([]);
  const [mediums, setMediums] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [editStandard, setEditStandard] = useState(null);
  const [editMedium, setEditMedium] = useState(null);
  const [editDivision, setEditDivision] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeletingStandard, setIsDeletingStandard] = useState(false);
  const [deleteDiv, setDeleteDiv] = useState(false);
  const institutecode = () => localStorage.getItem("institutecode");

  const onInputChangeStandard = (e) => {
    setStandard({ ...standard, [e.target.name]: e.target.value });
  };

  const onInputChangeMedium = (e) => {
    setMedium({ ...medium, [e.target.name]: e.target.value });
  };

  const onInputChangeDivision = (e) => {
    setDivision({ ...division, [e.target.name]: e.target.value });
  };

  const onSubmitStandard = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://pjsofttech.in:9443/createstandard?institutecode=${institutecode()}`, standard);
      Swal.fire("Success", "Standard Added Successfully!", "success");
      fetchStandards();
    } catch (error) {
      console.error("There was an error adding the standard!", error);
    }
  };

  const onSubmitMedium = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://pjsofttech.in:9443/save?institutecode=${institutecode()}`, medium);
      Swal.fire("Success", "Medium Added Successfully!", "success");
      fetchMediums();
    } catch (error) {
      console.error("There was an error adding the medium!", error);
    }
  };

  const onSubmitDivision = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://pjsofttech.in:9443/addDivision?institutecode=${institutecode()}`, division);
      Swal.fire("Success", "Division Added Successfully!", "success");
      fetchDivisions();
    } catch (error) {
      console.error("There was an error adding the division!", error);
    }
  };

  const fetchStandards = async () => {
    try {
      const result = await axios.get(`https://pjsofttech.in:9443/all?institutecode=${institutecode()}`);
      setStandards(result.data);
    } catch (error) {
      console.error("There was an error fetching the standards!", error);
    }
  };

  const fetchMediums = async () => {
    try {
      const result = await axios.get(`https://pjsofttech.in:9443/getall?institutecode=${institutecode()}`);
      setMediums(result.data);
    } catch (error) {
      console.error("There was an error fetching the mediums!", error);
    }
  };

  const fetchDivisions = async () => {
    try {
      const result = await axios.get(`https://pjsofttech.in:9443/getallDivision?institutecode=${institutecode()}`);
      setDivisions(result.data);
    } catch (error) {
      console.error("There was an error fetching the mediums!", error);
    }
  };

  const handleDeleteConfirmation = (id, type) => {
    setDeleteItemId(id);
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteConfirm(id, type);
      }
    });
  };
  
  const handleDeleteConfirm = async (id, type) => {
    try {
      if (type === "standard") {
        await axios.delete(`https://pjsofttech.in:9443/deleteStandard/${id}`);
        fetchStandards();
      } else if (type === "medium") {
        await axios.delete(`https://pjsofttech.in:9443/deleteMediumById/${id}`);
        fetchMediums();
      }else if(type === "division"){
        await axios.delete(`https://pjsofttech.in:9443/deletedivision/${id}`)
        fetchDivisions();
      }
      Swal.fire("Deleted!", "Item has been deleted.", "success");
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setDeleteItemId(null);
    }
  };

  const handleEditStandard = (standard) => {
    setEditStandard(standard);
    setStandard(standard);
  };

  const handleEditMedium = (medium) => {
    setEditMedium(medium);
    setMedium(medium);
  };

  const handleEditDivision = (division) => {
    setEditDivision(division);
    setDivision(division);
  };

  const onSubmitEditStandard = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://pjsofttech.in:9443/updateStandard/${editStandard.id}`, standard);
      Swal.fire("Success", "Standard Updated Successfully!", "success");
      fetchStandards();
      setEditStandard(null);
      setStandard({ standard: "" });
    } catch (error) {
      console.error("There was an error updating the standard!", error);
    }
  };

  const onSubmitEditMedium = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://pjsofttech.in:9443/updateMedium/${editMedium.mediumid}`, medium);
      Swal.fire("Success", "Medium Updated Successfully!", "success");
      fetchMediums();
      setEditMedium(null);
      setMedium({ mediumname: "" });
    } catch (error) {
      console.error("There was an error updating the medium!", error);
    }
  };

  const onSubmitEditDivision = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://pjsofttech.in:9443/updateDivision/${editDivision.id}`, division);
      Swal.fire("Success", "Division Updated Successfully!", "success");
      fetchDivisions();
      setEditDivision(null);
      setDivision({ division: "" });
    } catch (error) {
      console.error("There was an error updating the division!", error);
    }
  };

  useEffect(() => {
    fetchStandards();
    fetchMediums();
    fetchDivisions();
  }, []);

  return (
    <>
      <ToastContainer />
      <div style={{ marginTop: "10px" }}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Grid container spacing={2} className='textField-root'>
            <Grid item xs={4}>
              <form onSubmit={editStandard ? onSubmitEditStandard : onSubmitStandard} className="examForm">
                <TextField
                  type="text"
                  label={editStandard ? "Edit Standard Name:" : "Add Standard/Class Name:"}
                  className="form-control"
                  placeholder="Enter The Standard/Class Name"
                  name="standard"
                  value={standard.standard}
                  onChange={onInputChangeStandard}
                  fullWidth
                />
                <Button type="submit" variant="contained" color="primary" id="submit" style={{ marginTop: "10px" }}>
                  {editStandard ? "Update" : "Submit"}
                </Button>
              </form>
            </Grid>
            <Grid item xs={4}>
              <form onSubmit={editMedium ? onSubmitEditMedium : onSubmitMedium} className="examForm">
                <TextField
                  type="text"
                  label= {editMedium ? "Edit Medium Name:" : "Add Medium Name:"}
                  className="form-control"
                  placeholder="Enter The Medium Name"
                  name="mediumname"
                  value={medium.mediumname}
                  onChange={onInputChangeMedium}
                  fullWidth
                />
                <Button type="submit" variant="contained" color="primary" id="submit" style={{ marginTop: "10px" }}>
                  {editMedium ? "Update" : "Submit"}
                </Button>
              </form>
            </Grid>
            <Grid item xs={4}>
              <form onSubmit={editDivision ? onSubmitEditDivision : onSubmitDivision} className="examForm">
                <TextField
                  type="text"
                  label= {editMedium ? "Edit Division Name:" : "Add Division Name:"}
                  className="form-control"
                  placeholder="Enter The Medium Name"
                  name="division"
                  value={division.division}
                  onChange={onInputChangeDivision}
                  fullWidth
                />
                <Button type="submit" variant="contained" color="primary" id="submit" style={{ marginTop: "10px" }}>
                  {editDivision ? "Update" : "Submit"}
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table className="table-root" size="small">
                  <TableHead sx={{ background: "#f2f2f2" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Standard</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {standards.map((std) => (
                      <TableRow key={std.id}>
                        <TableCell>{std.id}</TableCell>
                        <TableCell>{std.standard}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={() => handleDeleteConfirmation(std.id, "standard")}>
                            <Delete />
                          </IconButton>
                          <IconButton color="primary" onClick={() => handleEditStandard(std)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table className="table-root" size="small">
                  <TableHead sx={{ background: "#f2f2f2" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Medium</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mediums.map((med) => (
                      <TableRow key={med.mediumid}>
                        <TableCell>{med.mediumid}</TableCell>
                        <TableCell>{med.mediumname}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={() => handleDeleteConfirmation(med.mediumid, "medium")}>
                            <Delete />
                          </IconButton>
                          <IconButton color="primary" onClick={() => handleEditMedium(med)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table className="table-root" size="small">
                  <TableHead sx={{ background: "#f2f2f2" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Division</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {divisions.map((div) => (
                      <TableRow key={div.id}>
                        <TableCell>{div.id}</TableCell>
                        <TableCell>{div.division}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={() => handleDeleteConfirmation(div.id, "division")}>
                            <Delete />
                          </IconButton>
                          <IconButton color="primary" onClick={() => handleEditDivision(div)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
}