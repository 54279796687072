export const gender = ["Male", "Female", "Other"];
export const bloodGroup = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
export const maritalStatus = ["Single", "Married"];
export const incomeRanges = [
  "0 to 1 Lakh",
  "1 Lakh to 2 Lakhs",
  "2 Lakhs to 3 Lakhs",
  "3 Lakhs to 5 Lakhs",
  "5 Lakhs to 8 Lakhs",
  "8 Lakhs to 12 Lakhs",
  "12 Lakhs and above",
];
export const title = ["Master/Kumar", "Miss/Kumari", "Mr/Shri", "Mrs/Smt."];

export const minority = ["Yes", "No"];
export const nationality = ["Indian", "Other"];
export const birthState = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];
export const birthDistrict = [
  "Ahmednagar",
  "Akola",
  "Amravati",
  "Aurangabad",
  "Beed",
  "Bhandara",
  "Buldhana",
  "Chandrapur",
  "Dhule",
  "Gadchiroli",
  "Gondia",
  "Hingoli",
  "Jalgaon",
  "Jalna",
  "Kolhapur",
  "Latur",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded",
  "Nandurbar",
  "Nashik",
  "Osmanabad",
  "Palghar",
  "Parbhani",
  "Pune",
  "Raigad",
  "Ratnagiri",
  "Sangli",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Wardha",
  "Washim",
  "Yavatmal",
];
export const castCategory = [
  "General",
  "OBC",
  "SC",
  "ST",
  "VJA",
  "NT B",
  "NT C",
  "NT D",
  "SBC",
  "SEBC",
  "EWS",
  "Orphan",
];
export const religionOptions = [
  "Hindu",
  "Muslim",
  "Christian",
  "Sikh",
  "Buddhist",
  "Jain",
  "Other",
  "Parsis",
];

export const yesNoOptions = ["Yes", "No"];
export const sportsName = [
  "Soccer",
  "Basketball",
  "Baseball",
  "Tennis",
  "Cricket",
  "Rugby",
  "Hockey",
  "Volleyball",
  "Golf",
  "Swimming",
  "Track and Field",
  "Boxing",
  "Wrestling",
  "Cycling",
  "Badminton",
  "Table Tennis",
  "Skiing",
  "Snowboarding",
  "Skateboarding",
  "Surfing",
];

export const levelOfParticipation = [
  "District Level",
  "State Level",
  "National Level",
  "International Level",
];

export const noOfYearsPlayed = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const sportsInjuries = [
  "None",
  "Sprains",
  "Fractures",
  "Dislocations",
  "Concussions",
  "Tendonitis",
  "Stress Fractures",
  "Muscle Strains",
];

export const heightOptions = [
  "150 cm",
  "155 cm",
  "160 cm",
  "165 cm",
  "170 cm",
  "175 cm",
  "180 cm",
  "185 cm",
  "190 cm",
  "195 cm",
  "200 cm",
];

export const weightOptions = [
  "50 kg",
  "55 kg",
  "60 kg",
  "65 kg",
  "70 kg",
  "75 kg",
  "80 kg",
  "85 kg",
  "90 kg",
  "95 kg",
  "100 kg",
];

export const stateOptions = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];
export const districtOptions = [
  "Ahmednagar",
  "Akola",
  "Amravati",
  "Aurangabad",
  "Beed",
  "Bhandara",
  "Buldhana",
  "Chandrapur",
  "Dhule",
  "Gadchiroli",
  "Gondia",
  "Hingoli",
  "Jalgaon",
  "Jalna",
  "Kolhapur",
  "Latur",
  "Mumbai City",
  "Mumbai Suburban",
  "Nagpur",
  "Nanded",
  "Nandurbar",
  "Nashik",
  "Osmanabad",
  "Palghar",
  "Parbhani",
  "Pune",
  "Raigad",
  "Ratnagiri",
  "Sangli",
  "Satara",
  "Sindhudurg",
  "Solapur",
  "Thane",
  "Wardha",
  "Washim",
  "Yavatmal",
];
export const speciallyAbledOptions = ["Yes", "No"];
export const specialPercentage = [
  "10%",
  "20%",
  "30%",
  "40%",
  "50%",
  "60%",
  "70%",
  "80%",
  "90%",
  "100%",
];
export const disabilityTypes = [
  "Visual Impairment",
  "Hearing Impairment",
  "Physical Disability",
  "Intellectual Disability",
  "Mental Illness",
  "Multiple Disabilities",
];
export const sportYesNo = ["Yes", "No"];
export const domicilebool = ["Yes", "No"];
export const boardOptions = [
  "CBSE",
  "ICSE",
  "SSC",
  "State Board",
  "HSC",
  "NIOS",
  "IB",
  "Other",
  // Add more board options as needed
];
export const ebcYesNo = ["Yes", "No"];

// errors.js
// errors.js
export const studentManagementErrors = {
  requiredField: '"This field is required."',
  invalidFormat: '"The input format is invalid."',
  minLength: '"The input is too short."',
  maxLength: '"The input is too long."',
  email: '"The email address is invalid."',
  passwordMismatch: '"Passwords do not match."',
  invalidCredentials: '"The username or password is incorrect."',
  accountLocked:
    '"The account is locked due to multiple failed login attempts."',
  tokenExpired: '"The session token has expired."',
  unauthorizedAccess: '"You do not have permission to access this resource."',
  connectionFailed: '"Failed to connect to the database."',
  queryFailed: '"Database query failed."',
  recordNotFound: '"The requested record was not found."',
  duplicateRecord: '"A record with the same identifier already exists."',
  timeout: '"The request timed out."',
  offline: '"The system is currently offline."',
  serverUnavailable: '"The server is currently unavailable."',
  requestFailed: '"The request failed due to a network error."',
  unexpectedError: '"An unexpected error occurred."',
  featureUnavailable: '"This feature is currently unavailable."',
  dataProcessingError: '"An error occurred while processing the data."',
  configurationError: '"There is a configuration error in the system."',
  userNotFound: '"User not found."',
  accessDenied: '"User does not have the necessary permissions."',
  profileIncomplete: '"User profile is incomplete."',
  actionNotAllowed: '"User action is not allowed."',
};
export const fProfessions = [
  "Doctor",
  "Engineer",
  "Teacher",
  "Lawyer",
  "Accountant",
  "Businessman",
  "Artist",
  "Farmer",
  "Other",
];

export const languages = [
  "English",
  "Hindi",
  "Tamil",
  "Telugu",
  "Kannada",
  "Malayalam",
  "Marathi",
  "Bengali",
  "Gujarati",
  "Punjabi",
  "Odia",
  "Assamese",
  "Urdu",
  "Nepali",
  "Sindhi",
  "Kashmiri",
  "Sanskrit",
];
export const grades = {
  "A+": "Excellent",
  A: "Very Good",
  "A-": "Good",
  "B+": "Above Average",
  B: "Average",
  "B-": "Below Average",
  "C+": "Satisfactory",
  C: "Acceptable",
  "C-": "Poor",
  "D+": "Very Poor",
  D: "Needs Improvement",
  F: "Fail",
};

export const scholarshipDropdown = [
  "Economically Backward Class SCHEME (EBC)",
  "PG SCHOLARSHIP",
  "PRAGATI SCHOLARSHIP SCHEME",
  "SAKSHAM SCHOLARSHIP SCHEME",
  "Other",
];
