import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  TextField,
  TableContainer,
  Box,
  Grid,
  IconButton,
  FormControl,
  MenuItem,
  DialogActions,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Close, FileDownloadOutlined } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import html2pdf from "html2pdf.js"; // Importing html2pdf.js
import numberToWords from "number-to-words"; // Importing number-to-words library
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const InstallmentById = ({ salaryId, onClose }) => {
  const getInstituteCode = () => localStorage.getItem("institutecode");
  const [installments, setInstallments] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [updatedInstallments, setUpdatedInstallments] = useState({});
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [openReceipt, setOpenReceipt] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState({}); // State for salary details
  const [selectedInvoice, setSelectedInvoice] = useState(""); // New state for selected invoice number

  const [invoiceNumbers, setInvoiceNumbers] = useState({
    1: "invoiceNo1",
    2: "invoiceNo2",
    3: "invoiceNo3",
  });

  useEffect(() => {
    const fetchInstallments = async () => {
      try {
        const response = await Axios.get(
          ` https://pjsofttech.in:19443/installments?institutecode=${getInstituteCode()}&id=${salaryId}`
        );
        setInstallments(response.data);
        setUpdatedInstallments(response.data); // Initialize with fetched data
      } catch (error) {
        console.error("Error fetching installments:", error);
      }
    };

    fetchInstallments();
  }, [salaryId]);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleChange = (key, e) => {
    setUpdatedInstallments((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  const handleSave = async () => {
    try {
      await Axios.put(
        ` https://pjsofttech.in:19443/updateMonthlySalaryInstallment/${salaryId}`,
        {
          installmentNo: 1,
          ...updatedInstallments,
        }
      );
      setInstallments(updatedInstallments);
      setIsEditing(false); // Exit edit mode after saving
    } catch (error) {
      console.error("Error updating installments:", error);
    }
  };

  // Extract installment data dynamically
  const getInstallmentDetails = () => {
    const details = [];
    for (let i = 1; i <= 3; i++) {
      if (installments[`amount${i}`] || installments[`status${i}`]) {
        details.push({
          installmentNumber: i,
          amount: installments[`amount${i}`],
          dueDate: installments[`dueDate${i}`],
          installmentDate: installments[`installmentDate${i}`],
          status: installments[`status${i}`],
          invoiceNo: installments[`invoiceNo${i}`], // Include invoice number
          billType: installments[`billType${i}`], // Include invoice number
          paidBy: installments[`paidBy${i}`], // Include invoice number
          transactionId: installments[`transactionId${i}`], // Include invoice number
          remark: installments[`remark${i}`], // Include invoice number
        });
      }
    }
    return details;
  };

  const getOrdinalSuffix = (num) => {
    if (num === 1) return "Install 1";
    if (num === 2) return "Install 2";
    if (num === 3) return "Install 3";
    return `${num}th Installment`;
  };

  // const handleGenerate = (installmentData) => {
  //   setSelectedInstallment(installmentData);
  //   setSelectedInvoice(installmentData.invoiceNo); // Use installment’s invoice number
  //   setOpenReceipt(true);
  // };

  const handleGenerate = (installmentData) => {
    if (installmentData.status === "Pending") {
      alert("Payment is still Pending");
    } else {
      setSelectedInstallment(installmentData);
      setSelectedInvoice(installmentData.invoiceNo); // Use installment’s invoice number
      setOpenReceipt(true);
    }
  };

  const downloadReceipt = () => {
    const receiptElement = document.getElementById("receipt");
    html2pdf()
      .from(receiptElement)
      .set({
        margin: 0.2,
        filename: "receipt.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save();
  };

  const installmentDetails = getInstallmentDetails();

  const convertAmountToWords = (amount) => {
    if (!amount) return "Zero";
    const words = numberToWords.toWords(amount);
    return `Rupees ${words.charAt(0).toUpperCase() + words.slice(1)} Only`;
  };

  // Function to fetch salary details
  const fetchSalaryDetails = async () => {
    try {
      const response = await Axios.get(
        ` https://pjsofttech.in:19443/getMonthlySalaryById/${salaryId}`
      );
      setSalaryDetails(response.data); // Store the salary details
    } catch (error) {
      console.error("Error fetching salary details:", error);
    }
  };

  useEffect(() => {
    fetchSalaryDetails(); // Fetch salary details when salaryId changes
  }, [salaryId]);

  const getMonthName = (monthNumber) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    // Month numbers are typically 1-12
    return monthNames[monthNumber - 1] || "Invalid Month";
  };
  

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        marginTop="1%"
        marginBottom="2%"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h6" gutterBottom fontWeight={"bold"}>
            Installment Details for Salary ID: {salaryId} (
            {salaryDetails.teacherName})
          </Typography>
        </Grid>
        <Grid item display={"flex"} justifyContent={"space-between"}>
          {isEditing ? (
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          ) : (
            <Button
              onClick={handleEditToggle}
              variant="outlined"
              color="success"
            >
              Edit Fields
            </Button>
          )}
          <IconButton onClick={onClose} color="error">
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Typography variant="body1" gutterBottom>
        Total Salary: ₹{salaryDetails.monthSalary || "NA"}
      </Typography>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead
            style={{
              backgroundColor: "#f2f2f2",
              justifyContent: "center",
            }}
          >
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>
                Install No.
              </TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Invoice No.</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Due Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Paid Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Paymemnt Mode</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Trans. Id</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Bill Type</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Receipt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installmentDetails.length > 0 ? (
              installmentDetails.map((installment, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {getOrdinalSuffix(installment.installmentNumber)}
                  </TableCell>
                  <TableCell>{installment.invoiceNo || "N/A"}</TableCell>
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        value={updatedInstallments[`amount${index + 1}`] || ""}
                        onChange={(e) => handleChange(`amount${index + 1}`, e)}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      `₹${installment.amount}`
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        type="date"
                        value={updatedInstallments[`dueDate${index + 1}`] || ""}
                        onChange={(e) => handleChange(`dueDate${index + 1}`, e)}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      installment.dueDate || "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        type="date"
                        value={
                          updatedInstallments[`installmentDate${index + 1}`] ||
                          ""
                        }
                        onChange={(e) =>
                          handleChange(`installmentDate${index + 1}`, e)
                        }
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      installment.installmentDate || "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <FormControl variant="outlined" size="small">
                        <TextField
                          select
                          value={
                            updatedInstallments[`paidBy${index + 1}`] || ""
                          }
                          onChange={(e) =>
                            handleChange(`paidBy${index + 1}`, e)
                          }
                          label="paidBy"
                        >
                          <MenuItem value="UPI">UPI</MenuItem>
                          <MenuItem value="Cash">Cash</MenuItem>
                          <MenuItem value="Cheque">Cheque</MenuItem>
                        </TextField>
                      </FormControl>
                    ) : (
                      <span>
                        <strong>{installment.paidBy || "N/A"}</strong>
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        type="text"
                        value={
                          updatedInstallments[`transactionId${index + 1}`] || ""
                        }
                        onChange={(e) =>
                          handleChange(`transactionId${index + 1}`, e)
                        }
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      installment.transactionId || "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <FormControl variant="outlined" size="small">
                        <TextField
                          select
                          value={
                            updatedInstallments[`billType${index + 1}`] || ""
                          }
                          onChange={(e) =>
                            handleChange(`billType${index + 1}`, e)
                          }
                          label="billType"
                        >
                          <MenuItem value="Invoice">Invoice</MenuItem>
                          <MenuItem value="Receipt">Receipt</MenuItem>
                        </TextField>
                      </FormControl>
                    ) : (
                      <span>
                        <strong>{installment.billType || "N/A"}</strong>
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <FormControl variant="outlined" size="small">
                        <TextField
                          select
                          value={
                            updatedInstallments[`status${index + 1}`] || ""
                          }
                          onChange={(e) =>
                            handleChange(`status${index + 1}`, e)
                          }
                          label="Status"
                        >
                          <MenuItem value="Paid">Paid</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                        </TextField>
                      </FormControl>
                    ) : (
                      <span
                        style={{
                          color:
                            installment.status === "Pending" ? "red" : "green",
                        }}
                      >
                        <strong>{installment.status || "N/A"}</strong>
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {isEditing ? (
                      <TextField
                        type="text"
                        value={updatedInstallments[`remark${index + 1}`] || ""}
                        onChange={(e) => handleChange(`remark${index + 1}`, e)}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      installment.remark || "N/A"
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleGenerate(installment)}
                      color="inherit"
                    >
                      <PrintIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No installments found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openReceipt}
        onClose={() => setOpenReceipt(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedInstallment ? (
            <Box sx={{ padding: 3 }}>
              <Box id="receipt">

              <Typography
                  mt={2}
                  variant="body2"
                  sx={{
                    borderTop: "8px solid purple",
                    padding: "10px",
                    alignItems: "center",
                    backgroundColor: "#f3e5f5",
                    textAlign:'center',
                    fontWeight:'bold',
                    display:'flex',
                    justifyContent:'space-between'
                  }}
                >
                   <Typography
                    variant="body1"
                    gutterBottom
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {selectedInstallment.billType} ID: {selectedInvoice}
                  </Typography>

                  <Typography
                variant="body1"
                gutterBottom
                style={{ textAlign: "center", fontWeight: "bold" }}
                >
                  
                  {getOrdinalSuffix(selectedInstallment.installmentNumber)} {selectedInstallment.billType} for {salaryDetails.teacherName} of {getMonthName(salaryDetails.month)} {salaryDetails.year}
                </Typography>
                 
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >{selectedInstallment.installmentDate}
                  </Typography>
                 
                </Typography>



                


                

                <Table
                size="small"
                sx={{
                  marginTop: "10px",
                  textAlign: "center",
                  justifyContent: "space-between",
                }}
              >
                <TableBody
                  sx={{
                    borderTop: "3px solid purple",
                    borderBottom: "3px solid purple",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {selectedInstallment.installmentDate && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                      Payment Date:
                      </TableCell>
                      <TableCell>{selectedInstallment.installmentDate}</TableCell>
                    </TableRow>
                  )}
                  
                  {salaryDetails.monthSalary && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                     Total Amount:
                      </TableCell>
                      <TableCell>₹{salaryDetails.monthSalary}</TableCell>
                    </TableRow>
                  )}
                  {selectedInstallment.amount && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                      {getOrdinalSuffix(selectedInstallment.installmentNumber)} Amount:
                      </TableCell>
                      <TableCell>₹{selectedInstallment.amount}</TableCell>
                    </TableRow>
                  )}
                  {selectedInstallment.paidBy && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                      Payment Mode:
                      </TableCell>
                      <TableCell>{selectedInstallment.paidBy}</TableCell>
                    </TableRow>
                  )}
                  {selectedInstallment.transactionId && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                      Transaction Id:
                      </TableCell>
                      <TableCell>{selectedInstallment.transactionId}</TableCell>
                    </TableRow>
                  )}
                  {selectedInstallment.status && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                      Status:
                      </TableCell>
                      <TableCell>{selectedInstallment.status}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>



              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "3px solid purple",
                  padding: "10px",
                }}
              >
                  <Typography style={{ fontWeight: "bold" }}>
                    Amount in Words:
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                  Rupees {convertAmountToWords(selectedInstallment.amount)} only.
                  </Typography>
                </div>

                <Typography
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "13px",
                    marginTop: "50px",
                    textAlign: "center",
                  }}
                >
                  ** This Receipt is System Generated **
                </Typography>
              </Box>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadReceipt} variant="contained" color="primary">
            <FileDownloadIcon />
            Download PDF
          </Button>
          <Button onClick={() => setOpenReceipt(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InstallmentById;
