import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField,
  IconButton
} from '@mui/material';
import StandardService from './Userservices2';
import { Delete } from '@mui/icons-material';
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2';
const Feetable = () => {
  const [standards, setStandards] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState({});
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  useEffect(() => {
    fetchStandards();
  }, []);

  const fetchStandards = async() => {
    try {
      const response = await StandardService.getAllStandards();
      setStandards(response.data);
    } catch (error) {
      console.error('Error fetching standards:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedStandard({ ...selectedStandard, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      await StandardService.updateStandard(selectedStandard.id, selectedStandard);
      setShowUpdateDialog(false);
      fetchStandards();
      Swal.fire("Success", "Fees Updated successfully", "success");
    } catch (error) {
      console.error('Error updating standard:', error);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
    try {
      await StandardService.deleteStandard(id);
      fetchStandards();
    } catch (error) {
      console.error('Error deleting standard:', error);
    }
  }
  });
  };

  return (
    <div className="container3">
      <TableContainer>
        <Table className="table-root">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Standard</TableCell>
              <TableCell>Medium</TableCell>
              <TableCell>Tuition</TableCell>
              <TableCell>Admission</TableCell>
              <TableCell>Practical</TableCell>
              <TableCell>Computer Class</TableCell>
              <TableCell>Exam</TableCell>
              <TableCell>Uniform</TableCell>
              <TableCell>Transport Bus</TableCell>
              <TableCell>Hostel</TableCell>
              <TableCell>Building Fund</TableCell>
              <TableCell>Library</TableCell>
              <TableCell>Sports</TableCell>
              <TableCell>GST</TableCell>
              {/* <TableCell>totalFeesAmount</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standards.map((standard) => (
              <TableRow key={standard.id}>
                <TableCell>{standard.id}</TableCell>
                <TableCell>{standard.standard}</TableCell>
                <TableCell>{standard.medium}</TableCell>
                <TableCell>{standard.tuitionFee}</TableCell>
                <TableCell>{standard.admissionFee}</TableCell>
                <TableCell>{standard.practicalFee}</TableCell>
                <TableCell>{standard.computerClassFee}</TableCell>
                <TableCell>{standard.examFees}</TableCell>
                <TableCell>{standard.uniformFee}</TableCell>
                <TableCell>{standard.transportBusFee}</TableCell>
                <TableCell>{standard.hostelFee}</TableCell>
                <TableCell>{standard.buildingFundFee}</TableCell>
                <TableCell>{standard.libraryFees}</TableCell>
                <TableCell>{standard.sportFees}</TableCell>
                <TableCell>{standard.gst}</TableCell>
                {/* <TableCell>{standard.totalFeesAmount}</TableCell> */}
                <TableCell>
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSelectedStandard(standard);
                      setShowUpdateDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(standard.id)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={showUpdateDialog} onClose={() => setShowUpdateDialog(false)} className="textField-root"  sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '90%' } }}>
        <DialogTitle>Update Fees</DialogTitle>
        <DialogContent>
          <form className="update-form">
            {[
              'standard', 'tuitionFee', 'admissionFee', 'practicalFee', 'computerClassFee',
              'examFees', 'uniformFee', 'transportBusFee', 'hostelFee', 'buildingFundFee',
              'libraryFees', 'sportFees', 'gst',
            ].map((key) => (
              <TextField
                key={key}
                margin="dense"
                label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                type="text"
                name={key}
                value={selectedStandard[key] || ''}
                onChange={handleInputChange}
                fullWidth
              />
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUpdateDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Feetable;
